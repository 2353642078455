import { TableRow, styled, SxProps } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));


export const weekendHighlight: SxProps = {
    backgroundColor: "info.light"
}

export const approvedHighlight: SxProps = {
    backgroundColor: 'success.light'
}