import { createSelector } from "@reduxjs/toolkit";
import { HATState } from "../..";
import { KindData, ProjectData, RegistrationData } from "../../types";
import { dayFilterPredicate, monthFilterPredicate } from "../../../utils";

export const registrationsSelector = (state: HATState): RegistrationData[] => state.data.registrations

export const kindsSelector = (state: HATState): KindData[] => {
    return state.data.kinds
}

export const userProjectsSelector = (state: HATState): ProjectData[] => {
    return state.data.projects
}

export const dayRegistrationsSelector = createSelector(registrationsSelector, (state: HATState, selectedDate: Date) => selectedDate, (registrations, selectedDate) => {
    return registrations.filter(r => dayFilterPredicate(r.date, selectedDate))
})

export const monthRegistrationsSelector = createSelector(registrationsSelector, (state: HATState, selectedDate: Date) => selectedDate, (registrations, selectedDate) => {
    return registrations.filter(r => monthFilterPredicate(r.date, selectedDate))
})