import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { FormSelectData } from "./types";
import { useState } from "react";
import { useAppTranslationByLocation } from "../../translations"

type SelectWithCheckboxesProps = {
    id: string,
    label: string,
    data: FormSelectData[],
    values: number[],
    onChange: (selectedValues: number[]) => void,
    visible?: boolean,
    required?: boolean
}

export const SelectWithCheckboxes: React.FC<SelectWithCheckboxesProps> = ({ id, label, data, values, onChange, visible = true, required = false }) => {
    const [selectedValues, setSelectedValues] = useState<number[]>([]);

    const { t } = useAppTranslationByLocation();

    //add "Select All" option
    data = [{ key: -1, value: -1, name: t("app.common.labels.selectAll") }, ...data]

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: { id: number, label: string }[]) => {
        var element = event.target as HTMLElement
        if (element.innerText === t("app.common.labels.selectAll") || element.id === '-1') {
            //select All clicked
            if (selectedValues.length === data.length) {    //check if all items are selected
                newValue = []   //unselect all items
            } else {
                newValue = data.map(o => ({ id: o.value, label: o.name }));     //select all items
            }
        } else {
            //ordinary item clicked
            //check if we need to check/uncheck the Select All option
            if (newValue.length === data.length - 1)
                if (newValue.find(i => i.id === -1)) {
                    newValue = newValue.filter(i => i.id !== -1)    //uncheck Select All
                }
                else {
                    newValue.push({ id: -1, label: t("app.common.labels.selectAll") })  //check Select All
                }
        }

        setSelectedValues(newValue.map(i => i.id));
        onChange(newValue.filter(i => i.id !== -1).map(i => i.id))
    }

    return (
        <Autocomplete
            multiple
            id={id}
            options={data.map(o => ({ id: o.key, label: o.name }))}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                        id={option.id.toString()}
                    />
                    {option.label}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleChange}
            value={selectedValues.map(v => ({ id: v, label: data.find(d => d.key === v)?.name ?? "no label" }))}
            size="small"
            renderInput={(params) => {
                if (required) {
                    return <TextField {...params} inputProps={{ ...params.inputProps, required: selectedValues.length === 0 }} size="small" label={label} required />
                } else {
                    return <TextField {...params} size="small" label={label} />
                }
            }
            }
            renderTags={(value, getTagProps) => {
                //display only limitTags number of chips when selecting the options to prevent the component from bloating
                value = value.filter(i => i.id !== -1)  //fiter out the Select all option

                const numTags = value.length;
                const limitTags = 8;

                return (
                    <>
                        {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={index}
                                label={option.label}
                            />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                    </>
                );
            }}
            sx={!visible ? { display: 'none' } : {}}
        />
    );
}