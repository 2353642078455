import { call, put, select } from 'redux-saga/effects'
import {
    setAllProjectsAction,
    setProjectsAction,
    setUserProjectsAction,
} from '../../redux/actions'
import { userInfoTokenSelector } from '../../selectors'
import { ProjectData } from '../../types'
import { projectService } from '../../../services'
import { consoleLoggerServiceFactory } from '../../../services'
import { CreateOrUpdateProjectViaSagaAction, LoadUserProjectsViaSagaAction } from './types'
import { loadAllProjectDataViaSagaAction } from './actions'

export function* loadCurrentUserProjectsViaSaga() {
    const token: string = yield select(userInfoTokenSelector)
    const projects: ProjectData[] = yield call(projectService(token).getOptionsForCurrentUser);

    consoleLoggerServiceFactory().logMessage("loadUserProjectsViaSaga: ", projects)
    yield put(setProjectsAction(projects))
}

export function* loadAllProjectsDataSaga() {
    const token: string = yield select(userInfoTokenSelector)
    const data: ProjectData[] = yield call(projectService(token).getAllProjects)
    consoleLoggerServiceFactory().logMessage("loadAllProjectsDataSaga: ", data)

    yield put(setAllProjectsAction(data));
}

//Handle both insert and update
export function* createOrUpdateProjectSaga(action: CreateOrUpdateProjectViaSagaAction) {
    const token: string = yield select(userInfoTokenSelector)

    if (action.payload.id === 0) {
        yield call(projectService(token).createProject, action.payload)
    } else {
        yield call(projectService(token).updateProject, action.payload)
    }
    yield put(loadAllProjectDataViaSagaAction());
}

export function* loadUserProjectsViaSaga(action: LoadUserProjectsViaSagaAction) {
    const token: string = yield select(userInfoTokenSelector)
    const projects: ProjectData[] = yield call(projectService(token).getOptionsForUser, action.payload.userId);

    consoleLoggerServiceFactory().logMessage("loadUserProjectsViaSaga: ", projects)
    yield put(setUserProjectsAction({ projects }))
}