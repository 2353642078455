import { ILoggerService } from "./types";
import { isProduction } from "../../utils";

export class ConsoleLoggerService implements ILoggerService {
    public logMessage = (message?: any, ...optionalParams: any[]): void => {
        try {
            if (!isProduction()) {
                console.log(`${new Date().toJSON()} ${message}`, ...optionalParams)
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    public logWarning = (message?: any, ...optionalParams: any[]): void => {
        try {
            console.warn(`${new Date().toJSON()} ${message}`, ...optionalParams)
        }
        catch (error) {
            console.error(error)
        }
    }

    public logError = (message?: any, ...optionalParams: any[]): void => {
        try {
            console.error(`${new Date().toJSON()} ${message}`, ...optionalParams)
        }
        catch (error) {
            console.error(error)
        }
    }
}
