import { useMsal } from '@azure/msal-react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { UserInfo } from '../../../state/types'
import { setUserInfoAction } from '../../../state/redux/actions'
import { loginRequest } from '../../../authentication/authConfig'
import { AuthenticationProps } from './types'
import { validateCurrentUserViaSagaAction, loadCurrentUserSettingsViaSagaAction } from '../../../state/sagas/actions'
import { consoleLoggerServiceFactory } from '../../../services'

export const Authentication: React.FC<AuthenticationProps> = ({ userInfo }) => {
    const { accounts, instance } = useMsal()
    const dispatch = useDispatch();


    useEffect(() => {
        //first check if the token is close to expiration
        const secondsToExpire = (userInfo.expiresOn.getTime() - new Date().getTime()) / 1000
        consoleLoggerServiceFactory().logMessage("secondsToExpire: ", secondsToExpire)
        if (secondsToExpire > 300) return

        const isLogin = userInfo.accessToken === ''

        consoleLoggerServiceFactory().logMessage("Accounts: ", accounts)

        if (accounts?.[0]) {
            const activeAccount = instance.getActiveAccount()
            if (!activeAccount) {
                instance.setActiveAccount(accounts[0])
            }

            instance.acquireTokenSilent({
                scopes: [
                    "api://ec9cf75a-a188-4cac-a07c-54c56292739b/access_as_user"
                ]
            }).then((result) => {
                let userInfo: UserInfo = {
                    fullName: result.account?.name!,
                    email: result.account?.username!,
                    accessToken: result.accessToken,
                    expiresOn: result.expiresOn!,
                    role: result.account?.idTokenClaims?.roles?.[0] ?? "no role",
                    hasAccess: true,  //will be checked a bit later
                }

                dispatch(setUserInfoAction({ userInfo }))

                //check if the user is known in database, but only on login, not on renew token
                isLogin && dispatch(validateCurrentUserViaSagaAction()) && dispatch(loadCurrentUserSettingsViaSagaAction())
            }).catch((error) => {
                consoleLoggerServiceFactory().logError("acquireTokenSilent failed", error)

                instance.loginRedirect(loginRequest).catch((e: unknown) => {
                    consoleLoggerServiceFactory().logError("Error in loginRedirect", e)
                })
            })
        }
    })

    return (<></>)    //no need to render anything
}