import { FormControlLabel, Switch } from "@mui/material";
import { FieldInputProps, useField } from "formik";
import { FC } from "react";
import { useAppTranslationByLocation } from "../../../../../translations";
import { WBS_PROJECTEDITOR_TRANSLATIONS } from "../../../translations";

export const ActiveSwitch: FC<FieldInputProps<boolean>> = ({ name, value }) => {
    const { t } = useAppTranslationByLocation();

    const [, , helpers] = useField(name);
    const { setValue } = helpers;

    return (
        <div>
            <FormControlLabel
                sx={{ alignSelf: "center", marginLeft: "4px" }}
                value="isactive"
                control={<Switch checked={value} onClick={() => setValue(!value)} />}
                label={`${t(WBS_PROJECTEDITOR_TRANSLATIONS.EDITOR_LABELS.IS_ACTIVE)}:`}
                labelPlacement="start"
            />
        </div>
    )
}