import { Modal } from "@mui/material"
import { Box } from "@mui/system"
import { modalStyle } from "../../../../../components/styling"
import { ProjectEditModalProps } from "../types"
import { ProjectEditor } from "./ProjectEditor"


export const ProjectEditModal: React.FC<ProjectEditModalProps> = ({ open, onClose, project, onProjectFormSubmitted }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <ProjectEditor project={project} onProjectFormSubmitted={onProjectFormSubmitted} />
            </Box>
        </Modal>
    )
}