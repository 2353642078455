import { HATState } from "../..";
import { UserInfo } from "../../types";

export const userInfoTokenSelector = (state: HATState): string => {
    return state.userInfo.accessToken
}

export const userInfoUserNameSelector = (state: HATState): string => {
    return state.userInfo.fullName
}

export const userInfoRolesSelector = (state: HATState): string => {
    return state.userInfo.role
}

export const userInfoSelector = (state: HATState): UserInfo => {
    return state.userInfo
}