import { HourDto, HourApi, RecurrenceDto } from "../../../apis/sms.web.api/generatedCode"
import { RecurrenceInfo, RegistrationData } from "../../state/types"
import { formatDate, getAuthorizationHeader } from "../../utils"
import { ILoggerService } from "../types"
import { mapRegistration, mapToChargeRecordDto, mapToRecurrenceDto } from "../utils"

export class HoursService {
    _loggerService: ILoggerService
    _hoursApi: HourApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._hoursApi = new HourApi(undefined, basePath)
        this._token = token
    }

    getHoursOfUserBetweenDates = async (from: Date, to: Date): Promise<RegistrationData[]> => {
        try {
            const response = await this._hoursApi.apiHourGetHoursBetweenDatesGet(formatDate(from), formatDate(to), getAuthorizationHeader(this._token));

            return response.data.map(item => mapRegistration(item));
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return [];
        }
    }

    deleteHours = async ({ id }: { id: number }): Promise<void> => {
        try {
            await this._hoursApi.apiHourDeleteDelete(id, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }

    approveHours = async ({ registrationIds }: { registrationIds: number[] }): Promise<void> => {
        try {
            await this._hoursApi.apiHourApproveHoursPost(registrationIds, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }

    createOrUpdateHours = async (registrations: RegistrationData[]): Promise<RegistrationData[]> => {
        try {
            const mappedData: HourDto[] = registrations.map(item => mapToChargeRecordDto(item))
            const response = await this._hoursApi.apiHourCreateOrUpdateHoursPost(mappedData, getAuthorizationHeader(this._token));
            return response.data.map(hourViewDTO => mapRegistration(hourViewDTO))
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return []
        }
    }

    createRecurrentHours = async (registration: RegistrationData, recurrence: RecurrenceInfo): Promise<RegistrationData[]> => {
        try {
            const mappedData: RecurrenceDto = mapToRecurrenceDto(registration, recurrence)
            const response = await this._hoursApi.apiHourCreateRecurrentHoursPost(mappedData, getAuthorizationHeader(this._token));
            return response.data.map(hourViewDTO => mapRegistration(hourViewDTO))
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return []
        }
    }

    getXmlExportForPeriod = async (date: Date): Promise<string> => {
        try {
            const result = await this._hoursApi.apiHourGenerateMyTEXMLExportGet(formatDate(date), getAuthorizationHeader(this._token));
            return result.data.toString()
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return "";
        }
    }
}
