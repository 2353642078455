import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { LANG_PARAM, LENGTH_OF_LANGUAGE } from "./constants"
import { consoleLoggerServiceFactory } from "../services"

export const useAppTranslation = () => {
    return useTranslation('hat-web')
}

export const useAppTranslationByLocation = () => {
    const appTranslation = useAppTranslation()
    const location = useLocation()

    useEffect(() => {
        const indexOfLangParameter = getIndexOfLangParameterFromLocation(location.search)

        if (-1 !== indexOfLangParameter) {
            const parsedLanguage = getParsedLanguage(location.search, indexOfLangParameter)

            if (appTranslation.i18n.options.debug) {
                consoleLoggerServiceFactory().logMessage("parsedLanguage: ", parsedLanguage)
            }

            if (parsedLanguage !== appTranslation.i18n.language) {
                appTranslation.i18n.changeLanguage(parsedLanguage)
            }
        }

        if (appTranslation.i18n.options.debug) {
            consoleLoggerServiceFactory().logMessage("language: ", location.search)
            consoleLoggerServiceFactory().logMessage("translation language: ", appTranslation.i18n.language)
        }
    }, [location.search, appTranslation.i18n])

    return appTranslation
}


const getIndexOfLangParameterFromLocation = (search: string = "") => {
    const lowercasedSearchParam = search.toLocaleLowerCase()
    return lowercasedSearchParam.indexOf(LANG_PARAM)
}

const getParsedLanguage = (search: string = "", indexOfLangParameter: number) => {
    const lengthOfParam = LANG_PARAM.length
    return search.substring(indexOfLangParameter + lengthOfParam, indexOfLangParameter + lengthOfParam + LENGTH_OF_LANGUAGE)
}
