import { ProjectApi } from "../../../apis/sms.web.api/generatedCode"
import { ProjectData } from "../../state/types"
import { getAuthorizationHeader } from "../../utils"
import { ILoggerService } from "../types"
import { mapProject } from "../utils"

export class ProjectService {
    _loggerService: ILoggerService
    _projectApi: ProjectApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._projectApi = new ProjectApi(undefined, basePath)
        this._token = token
    }

    getAllProjects = async (): Promise<ProjectData[]> => {
        try {
            const response = await this._projectApi.apiProjectGetAllProjectsGet(getAuthorizationHeader(this._token));
            return response.data.map(item => mapProject(item)).sort((a, b) => a.name.localeCompare(b.name));
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return [];
        }
    }

    getOptionsForUser = async (userId: number): Promise<ProjectData[]> => {
        try {
            const response = await this._projectApi.apiProjectGetOptionsOfUserGet(userId, getAuthorizationHeader(this._token))
            return response.data.map(item => mapProject(item)).sort((a, b) => a.name.localeCompare(b.name));
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return [];
        }
    }

    getOptionsForCurrentUser = async (): Promise<ProjectData[]> => {
        try {
            const response = await this._projectApi.apiProjectGetOptionsOfCurrentUserGet(getAuthorizationHeader(this._token))
            return response.data.map(item => mapProject(item)).sort((a, b) => a.name.localeCompare(b.name));
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return [];
        }
    }

    createProject = async (project: ProjectData): Promise<void> => {
        try {
            await this._projectApi.apiProjectCreatePost({
                id: project.id,
                name: project.name,
                isActive: project.active
            }, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }

    updateProject = async (project: ProjectData): Promise<void> => {
        try {
            await this._projectApi.apiProjectUpdatePut({
                id: project.id,
                name: project.name,
                isActive: project.active
            }, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }
}
