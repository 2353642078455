import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { FALLBACK_LANGUAGE } from './constants';

export { useAppTranslation, useAppTranslationByLocation } from './hooks'

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: FALLBACK_LANGUAGE,
        debug: false,
        interpolation: {
            escapeValue: false
        }
    });

export { i18n }
