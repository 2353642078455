import { ILoggerService } from "../types"
import { FilterDto, ReportApi } from "../../../apis/sms.web.api/generatedCode"
import { formatDate, getAuthorizationHeader } from "../../utils"

export class ReportService {

    _loggerService: ILoggerService
    _reportApi: ReportApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._reportApi = new ReportApi(undefined, basePath)
        this._token = token
    }

    getDetailReport = async (from: Date, to: Date, projectIds: number[], wbsIds: number[], kindIds: number[]): Promise<{ data: string, contentType: string }> => {
        try {
            const params: FilterDto = {
                startDate: formatDate(from),
                endDate: formatDate(to),
                projectsId: projectIds,
                wbsId: wbsIds,
                kindId: kindIds
            }
            const result = await this._reportApi.apiReportGetDetailReportPost(params, { responseType: 'blob', ...getAuthorizationHeader(this._token) });
            return { data: result.data, contentType: result.headers['content-type'] }
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return { data: '', contentType: '' }
        }
    }

    getTimeReport = async (from: Date, to: Date, projectIds: number[], wbsIds: number[], kindIds: number[]): Promise<{ data: string, contentType: string }> => {
        try {
            const params: FilterDto = {
                startDate: formatDate(from),
                endDate: formatDate(to),
                projectsId: projectIds,
                wbsId: wbsIds,
                kindId: kindIds
            }
            const result = await this._reportApi.apiReportGetTimeReportPost(params, { responseType: 'blob', ...getAuthorizationHeader(this._token) });
            return { data: result.data, contentType: result.headers['content-type'] }
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return { data: '', contentType: '' }
        }
    }
}