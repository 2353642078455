import { call, put, select } from 'redux-saga/effects'
//import { logErrorMessage, logInfoMessage } from '../../redux/actions'
import {
    setKindsAction,
} from '../../redux/actions'
import { userInfoTokenSelector } from '../../selectors'
import { KindData } from '../../types'
import { kindService } from '../../../services'

//Load list of Task kinds 
export function* loadKindsSaga() {
    const token: string = yield select(userInfoTokenSelector)
    const data: KindData[] = yield call(kindService(token).getKinds)

    yield put(setKindsAction(data));
}