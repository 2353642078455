import { Button, SelectChangeEvent, Stack, Accordion, AccordionSummary, AccordionDetails, Typography, Checkbox, InputAdornment } from '@mui/material';
import { FormEventHandler, useState, useEffect } from "react";
import { DateSelect, FormSelect, FormTextField } from '../../../../components/formElements'
import { DayInputFormProps } from './types';
import { DAY_INPUT_FORM_LABELS } from './translations';
import { useAppTranslationByLocation } from '../../../../translations';
import { RecurrencePatternFE } from '../../../../state/types';

const DUPLICATE_BUTTON_ID = "duplicate"

export const DayInputForm: React.FC<DayInputFormProps> = ({ kinds, projects, onFormSubmitted, selectedDate, selectedRegistration, showRecurrence = false }) => {
    const [kindId, setKindId] = useState<string>('');
    const [projectId, setProjectId] = useState<string>('');
    const [wbsId, setWbsId] = useState<string>('');
    const [ticketNumber, setTicketNumber] = useState<string>('');
    const [activity, setActivity] = useState<string>('');
    const [hours, setHours] = useState<string>('');
    const [idCounter, setIdCounter] = useState<number>(-1);
    const [recurrenceActive, setRecurrenceActive] = useState<boolean>(false);
    const [recurrenceType, setRecurenceType] = useState<string>(RecurrencePatternFE.DAILY);
    const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date>(selectedDate);
    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        if (selectedRegistration !== null) {
            setKindId(selectedRegistration.typeId.toString());
            setProjectId(selectedRegistration.projectId.toString());
            setWbsId(selectedRegistration.wbsId.toString());
            setTicketNumber(selectedRegistration.ticket);
            setActivity(selectedRegistration.activity);
            setHours(selectedRegistration.hours === 0 ? '' : selectedRegistration.hours.toString());
        } else {
            emptyForm();
            if (projects.length === 1) {
                setProjectId(projects[0].id.toString())
                if (projects[0]?.WBS && projects[0].WBS.length === 1) {
                    setWbsId(projects[0].WBS[0].id.toString())
                } else {
                    setWbsId('');
                }
            }
        }
    }, [selectedRegistration, projects])

    const emptyForm = () => {
        setKindId('');
        setProjectId('');
        setWbsId('');
        setTicketNumber('');
        setActivity('');
        setHours('');
    }

    const handleKindChange = (event: SelectChangeEvent) => {
        setKindId(event.target.value);
    }

    const handleRecurrenceTypeChange = (event: SelectChangeEvent) => {
        setRecurenceType(event.target.value);
    }

    const handleProjectChange = (event: SelectChangeEvent) => {
        setProjectId(event.target.value);
        const selectedProject = projects.find(proj => Number(event.target.value) === proj.id)
        if (selectedProject?.WBS && selectedProject.WBS.length === 1) {
            setWbsId(selectedProject.WBS[0].id.toString())
        } else {
            setWbsId('');
        }
    }

    const handleWbsChange = (event: SelectChangeEvent) => {
        setWbsId(event.target.value);
    }

    const handleTicketNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTicketNumber(event.target.value);
    }

    const handleActivityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setActivity(event.target.value);
    }

    const onRecurrenceEndDateChanged = (newDate: Date) => {
        setRecurrenceEndDate(newDate)
    }

    const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // eslint-disable-next-line no-useless-escape
        var value = event.target.value.replace(/[^\.|\d]/g, "") //get rid of non-numeric characters - allow only . and digits
        var hours = parseFloat(value)
        if (isNaN(hours)) {
            setHours("")
        } else if (value.match(/\./g)?.length === 1 && value.endsWith(".")) {   //too many dots, allow only one
            setHours(value)
        } else {
            hours = Math.round(hours / 0.5) * 0.5;      //round to nearest 0.5
            setHours(hours.toString());
        }
    }

    const toggleRecurrenceActive = () => {
        setRecurrenceActive(!recurrenceActive)
    }

    const getNewId = () => {
        setIdCounter(idCounter - 1);
        return idCounter
    }

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        if (parseFloat(hours) <= 0) return;
        if (recurrenceActive && (recurrenceEndDate < selectedDate)) return;

        const duplicateButtonClicked = (e.nativeEvent as SubmitEvent).submitter?.id === DUPLICATE_BUTTON_ID

        onFormSubmitted({
            id: ((selectedRegistration !== null) && !duplicateButtonClicked) ? selectedRegistration.id : getNewId(),     //use negative numbers for newly created records
            projectId: parseInt(projectId),
            projectName: projects.find(c => c.id === parseInt(projectId))?.name!,
            date: selectedDate,
            wbsId: parseInt(wbsId),
            wbsName: projects.find(c => c.id === parseInt(projectId))?.WBS.find(w => w.id === parseInt(wbsId))?.name!,
            wbsCode: projects.find(c => c.id === parseInt(projectId))?.WBS.find(w => w.id === parseInt(wbsId))?.code!,
            ticket: ticketNumber,
            activity: activity,
            hours: parseFloat(hours),
            typeId: parseInt(kindId),
            typeName: kinds.find(k => k.id === parseInt(kindId))?.name!,
            isApproved: false,
            userId: selectedRegistration !== null ? selectedRegistration.userId : 0
        }, recurrenceActive ? {
            pattern: recurrenceType as RecurrencePatternFE,
            endDate: recurrenceEndDate
        } : undefined
        );

        emptyForm();
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{ mt: 1 }}>
                <FormSelect
                    id="select-project"
                    label={t(DAY_INPUT_FORM_LABELS.SELECT_PROJECT)}
                    value={projectId}
                    data={projects.map(item => ({ key: item.id, value: item.id, name: item.name }))}
                    onChange={handleProjectChange} />

                <FormSelect
                    id="select-wbs"
                    label={t(DAY_INPUT_FORM_LABELS.SELECT_WBS)}
                    value={wbsId}
                    data={projects.find((c) => c.id === parseInt(projectId))?.WBS.map(item => ({ key: item.id, value: item.id, name: `${item.code} - ${item.name}` }))}
                    onChange={handleWbsChange} />

                <FormSelect id="select-kind"
                    label={t(DAY_INPUT_FORM_LABELS.SELECT_KIND)}
                    value={kindId}
                    data={kinds.map(item => ({ key: item.id, value: item.id, name: item.name }))}
                    onChange={handleKindChange} />

                <FormTextField fullWidth
                    id='input-ticket-number'
                    label={t(DAY_INPUT_FORM_LABELS.TICKET_NUMBER)}
                    value={ticketNumber}
                    onChange={handleTicketNumberChange} />
                <FormTextField fullWidth required
                    id='input-activity'
                    label={t(DAY_INPUT_FORM_LABELS.ACTIVITY)}
                    multiline
                    rows={3}
                    value={activity}
                    maxlength={1000}
                    onChange={handleActivityChange} />
                <FormTextField fullWidth required error={parseFloat(hours) <= 0}
                    id='input-hours'
                    label={t(DAY_INPUT_FORM_LABELS.HOURS)}
                    type="text"
                    autoComplete='off'
                    InputProps={{
                        endAdornment: <InputAdornment position="start">h</InputAdornment>,
                        inputMode: 'numeric',
                    }}
                    value={hours}
                    onChange={handleHoursChange} />
                {showRecurrence &&
                    <Accordion disableGutters expanded={recurrenceActive} onChange={toggleRecurrenceActive}>
                        <AccordionSummary >
                            <Stack direction="row" sx={{ margin: '0px' }}>
                                <Checkbox checked={recurrenceActive} />
                                <Typography sx={{ margin: 'auto' }}>{t(DAY_INPUT_FORM_LABELS.RECURRENCE_OPTIONS)}</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                <FormSelect id="select-recurrence-type"
                                    label={t(DAY_INPUT_FORM_LABELS.RECURRENCE_TYPE)}
                                    value={recurrenceType}
                                    onChange={handleRecurrenceTypeChange}
                                    data={[
                                        { key: 0, value: RecurrencePatternFE.DAILY, name: t(DAY_INPUT_FORM_LABELS.DAILY) },
                                        { key: 1, value: RecurrencePatternFE.WEEKLY, name: t(DAY_INPUT_FORM_LABELS.WEEKLY) }
                                    ]}
                                    required={false} />
                                <DateSelect label={t(DAY_INPUT_FORM_LABELS.RECURRENCE_END_DATE)}
                                    selectedDate={recurrenceEndDate}
                                    onDateChanged={onRecurrenceEndDateChanged}
                                    error={recurrenceEndDate < selectedDate} />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                }
                <Button
                    type="submit"
                    variant="contained">
                    {(selectedRegistration !== null) && selectedRegistration.id > 0 ? t(DAY_INPUT_FORM_LABELS.UPDATE) : t(DAY_INPUT_FORM_LABELS.CREATE)}
                </Button>
                <Button
                    id={DUPLICATE_BUTTON_ID}
                    type="submit"
                    variant="contained"
                    disabled={!((selectedRegistration !== null) && selectedRegistration.id > 0)}>
                    {t(DAY_INPUT_FORM_LABELS.DUPLICATE)}
                </Button>
            </Stack>
        </form>
    );
}