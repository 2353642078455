import { createSelector } from "@reduxjs/toolkit";
import { HATState } from "../..";
import { ProjectData, RegistrationData, UserData, WBSData } from "../../types";
import { monthFilterPredicate } from "../../../utils";

export const userRegistrationsSelector = (state: HATState): RegistrationData[] => state.adminData.userRegistrations

export const allProjectsSelector = (state: HATState): ProjectData[] => state.adminData.allProjects

export const allUsersSelector = (state: HATState): UserData[] => state.adminData.allUsers

export const selectedUserProjectsSelector = (state: HATState): ProjectData[] => state.adminData.userProjects

export const userWbsSelector = (state: HATState): WBSData[] => state.adminData.wbsForUser

export const usersForWbsSelector = (state: HATState): UserData[] => state.adminData.usersForWbs

export const activeUsersForWbsSelector = createSelector(usersForWbsSelector, (users) => users.filter(u => u.isActive))

export const selectedUserMonthRegistrationsSelector = createSelector(userRegistrationsSelector,
    (state: HATState, selectedDate: Date) => selectedDate,
    (registrations, selectedDate) => registrations.filter(r => monthFilterPredicate(r.date, selectedDate)))

export const allActiveUsersSelector = createSelector(allUsersSelector, (allUsers) => allUsers.filter(u => u.isActive))