import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Link, TableCell } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { formatHours, getPeriodDays, isWeekend, periodFilterPredicate } from '../../../../utils'
import { ProjectRow } from '../index'
import { formatDate, getShortDayName, getSumOfHours } from '../../../../utils'
import { PeriodOverviewProps } from './types';
import { weekendHighlight } from '../styles';
import { PERIOD_OVERVIEW_LABELS } from './translations';
import { useAppTranslationByLocation } from '../../../../translations';

export const PeriodOverview: React.FC<PeriodOverviewProps> = ({ selectedDate, projects, registrations, onRegistrationClicked, onRegistrationBulkEditClicked }) => {
    const { t } = useAppTranslationByLocation();

    const periodRegistrations = registrations.filter((r) => periodFilterPredicate(r.date, selectedDate))

    //Get list of distinct project id's
    const getProjectIds = (): number[] => [...new Set(periodRegistrations.map(r => r.projectId).sort((a, b) => a - b))]

    //Get total hours worked on a given day
    const getDailySum = (day: Date): string => {
        return formatHours(getSumOfHours(periodRegistrations.filter(r => r.date.getDate() === day.getDate())))
    }

    //Format date in the headers of the table
    const formatHeaderDate = (date: Date): string => `${getShortDayName(date)} ${date.getDate()}`

    const periodDays = getPeriodDays(selectedDate)

    return (
        <TableContainer component={Paper}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.PROJECT)}</TableCell>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.WBS)}</TableCell>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.TICKET)}</TableCell>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.KIND)}</TableCell>
                        <TableCell />
                        {periodDays.map(day =>
                            <TableCell key={day.getDate()} align='center' sx={isWeekend(day) ? weekendHighlight : null}>
                                <Link component={RouterLink} underline="hover" color="primary.main" textAlign={"center"} to={`/dayView/date/${formatDate(day)}`}>
                                    {formatHeaderDate(day)}
                                </Link>
                            </TableCell>)}
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.TOTAL)}</TableCell>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.TOTAL)} {t(PERIOD_OVERVIEW_LABELS.WBS)}</TableCell>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.TOTAL)} {t(PERIOD_OVERVIEW_LABELS.PROJECT)}</TableCell>
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.TOTAL)} {t(PERIOD_OVERVIEW_LABELS.MONTH)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getProjectIds().map(id =>
                        <ProjectRow
                            key={id}
                            project={projects.find(p => p.id === id)!}
                            registrations={periodRegistrations.filter(r => r.projectId === id)}
                            totalHours={getSumOfHours(registrations.filter(r => r.projectId === id))}
                            onRegistrationClicked={onRegistrationClicked}
                            onRegistrationBulkEditClicked={onRegistrationBulkEditClicked}
                        />
                    )}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>{t(PERIOD_OVERVIEW_LABELS.TOTAL)}</TableCell>
                        <TableCell />
                        {periodDays.map(day =>
                            <TableCell key={day.getDate()} align="center" sx={isWeekend(day) ? weekendHighlight : null}>
                                {getDailySum(day)}
                            </TableCell>)}
                        <TableCell align="right">{formatHours(getSumOfHours(periodRegistrations))}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>
    )
}