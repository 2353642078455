import { combineReducers } from 'redux'
import { loggerReducer } from './logger'
import { userInfoReducer } from './userInfo'
import { dataReducer } from './data'
import { adminDataReducer } from './adminData'
import { userSettingsReducer } from './settings'
import { notificationReducer } from './notifications'

export const hatReducer = combineReducers({
    userInfo: userInfoReducer,
    logger: loggerReducer,
    data: dataReducer,
    adminData: adminDataReducer,
    settings: userSettingsReducer,
    notifications: notificationReducer,
})