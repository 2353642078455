import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../../authentication/authConfig'
import { useEffect } from 'react'
import { consoleLoggerServiceFactory } from '../../../services'

export const Login = () => {
    const { instance } = useMsal()

    useEffect(() => {
        instance.ssoSilent(loginRequest).catch((e: unknown) => {
            consoleLoggerServiceFactory().logError("Error in ssoSilent", e)
            if (((e as Error).name = 'InteractionRequiredAuthError')) {
                return instance.loginRedirect(loginRequest).catch((e: unknown) => {
                    consoleLoggerServiceFactory().logError("Error in loginRedirect", e)
                })
            }
        })
    }, [instance])

    return (<></>)    //no need to render anything
}