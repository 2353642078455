import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DayRegistration } from '../dayRegistration/DayRegistration';
import { RegistrationData } from "../../../../state/types"
import { useAppTranslationByLocation } from '../../../../translations';
import { DAY_REGISTRATION_BOX_LABELS } from './translations';

type DayRegistrationBoxProps = {
  registrations: RegistrationData[],
  onDeleteButtonClicked: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void,
  onRegistrationSelected: (data: RegistrationData) => void
}

export const DayRegistrationBox: React.FC<DayRegistrationBoxProps> = ({ registrations, onDeleteButtonClicked, onRegistrationSelected }) => {
  const { t } = useAppTranslationByLocation();

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{t(DAY_REGISTRATION_BOX_LABELS.PROJECT)}</TableCell>
            <TableCell>{t(DAY_REGISTRATION_BOX_LABELS.WBS)}</TableCell>
            <TableCell>{t(DAY_REGISTRATION_BOX_LABELS.KIND)}</TableCell>
            <TableCell>{t(DAY_REGISTRATION_BOX_LABELS.TICKET)}</TableCell>
            <TableCell>{t(DAY_REGISTRATION_BOX_LABELS.HOURS)}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {registrations.map((item: RegistrationData) =>
            <TableRow hover key={item.id} onClick={() => onRegistrationSelected(item)}>
              <DayRegistration registration={item} onDeleteButtonClicked={onDeleteButtonClicked} />
            </TableRow>)
          }
        </TableBody>
      </Table>
    </TableContainer>

  );
}