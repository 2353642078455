import { ConsoleLoggerService } from './loggerService'
import { HoursService } from './hoursService'
import { ProjectService } from './projectService'
import { WbsService } from './wbsService'
import { UserService } from './userService'
import { KindService } from './kindService'
import { ImpersonationService } from './impersonationService'
import { ReportService } from './reportService'

export { ConsoleLoggerService } from './loggerService'


const basePath = process.env.REACT_APP_API_SERVER ?? 'https://localhost:52570'

export const consoleLoggerServiceFactory = () => new ConsoleLoggerService()

export const projectService = (token: string) => new ProjectService(consoleLoggerServiceFactory(), token, basePath)
export const wbsService = (token: string) => new WbsService(consoleLoggerServiceFactory(), token, basePath)
export const hoursService = (token: string) => new HoursService(consoleLoggerServiceFactory(), token, basePath)
export const userService = (token: string) => new UserService(consoleLoggerServiceFactory(), token, basePath)
export const kindService = (token: string) => new KindService(consoleLoggerServiceFactory(), token, basePath)
export const impersonationService = (token: string) => new ImpersonationService(consoleLoggerServiceFactory(), token, basePath)
export const reportService = (token: string) => new ReportService(consoleLoggerServiceFactory(), token, basePath)