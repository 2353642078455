import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useAppTranslationByLocation } from '../../translations';

type DateSelectProps = TextFieldProps & {
  label?: string,
  selectedDate: Date,
  onDateChanged: (selectedDate: Date) => void,
}

const DEFAULT_TEXT = "default_text"

export const DateSelect: React.FC<DateSelectProps> = ({ label = DEFAULT_TEXT, selectedDate, onDateChanged, ...textFieldProps }) => {
  const [value, setValue] = useState<Dayjs>(dayjs(selectedDate));
  const { t } = useAppTranslationByLocation()

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue === null) return;
    setValue(newValue);
    onDateChanged(newValue.toDate());
  };

  useEffect(() => {
    setValue(dayjs(selectedDate));
  }, [selectedDate]);

  if (label === DEFAULT_TEXT) {
    label = t("app.common.labels.selectDate")
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <DatePicker
          label={label}
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} fullWidth {...textFieldProps} />
          )}
        />
      </div>
    </LocalizationProvider>
  );
};