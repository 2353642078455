import { WBSApi } from "../../../apis/sms.web.api/generatedCode"
import { UserData, WBSData } from "../../state/types"
import { getAuthorizationHeader } from "../../utils"
import { ILoggerService, ResponseStatus } from "../types"
import { mapUser, mapWBS } from "../utils"

export class WbsService {
    _loggerService: ILoggerService
    _wbsApi: WBSApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._wbsApi = new WBSApi(undefined, basePath)
        this._token = token
    }

    createWBS = async (wbs: WBSData): Promise<WBSData | null> => {
        try {
            const result = await this._wbsApi.apiWBSCreatePost(
                { id: wbs.id, name: wbs.name, code: wbs.code, projectId: wbs.projectId, isActive: wbs.active },
                getAuthorizationHeader(this._token));
            return mapWBS(result.data)
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return null
        }
    }

    updateWBS = async (wbs: WBSData): Promise<WBSData | null> => {
        try {
            const result = await this._wbsApi.apiWBSUpdatePut(
                { id: wbs.id, name: wbs.name, code: wbs.code, projectId: wbs.projectId, isActive: wbs.active },
                getAuthorizationHeader(this._token));
            return mapWBS(result.data)
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return null
        }
    }


    deleteWBS = async (wbsId: number): Promise<ResponseStatus> => {
        try {
            const result = await this._wbsApi.apiWBSDeleteDelete(wbsId, { ...getAuthorizationHeader(this._token), validateStatus: (status) => status < 500 });
            if (result.status === 204) return ResponseStatus.OK
            return ResponseStatus.WARNING
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return ResponseStatus.ERROR;
        }
    }

    getUsersForWbs = async (wbsId: number): Promise<UserData[]> => {
        try {
            const result = await this._wbsApi.apiWBSGetUsersForWbsGet(wbsId, getAuthorizationHeader(this._token));
            return result.data.map(u => mapUser(u))
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return []
        }
    }

    saveUsersForWbs = async (wbsId: number, userIds: number[]): Promise<{ userData: UserData[], responseStatus: ResponseStatus }> => {
        try {
            const result = await this._wbsApi.apiWBSSaveUsersForWbsPost(wbsId, userIds, getAuthorizationHeader(this._token));
            return {
                userData: result.data.map(u => mapUser(u)),
                responseStatus: ResponseStatus.OK
            }
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return { userData: [], responseStatus: ResponseStatus.ERROR }
        }
    }

    getWbsForUser = async (userId: number): Promise<WBSData[]> => {
        try {
            const result = await this._wbsApi.apiWBSGetWbsForUserGet(userId, getAuthorizationHeader(this._token));
            return result.data.map(u => mapWBS(u))
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return []
        }
    }

    saveWbsForUser = async (userId: number, wbsIds: number[]): Promise<void> => {
        try {
            await this._wbsApi.apiWBSSaveWbsForUserPost(userId, wbsIds, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }
}
