import { UserSettings } from '../../../types';
import * as Constants from './constants';
import { UpdateDarkModeAction, UpdateAllSettingsAction } from './types';

export const setAllUserSettingsAction = (data: UserSettings): UpdateAllSettingsAction => {
  return {
    type: Constants.UPDATE_ALL_USER_SETTINGS,
    payload: data,
  };
};

export const setDarkModeAction = (darkMode: boolean): UpdateDarkModeAction => {
  return {
    type: Constants.UPDATE_DARK_MODE,
    payload: darkMode,
  };
};