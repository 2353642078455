import { ResponseStatus } from "../../../../services/types"
import * as Constants from "../../actions/notifications/constants"
import { NotificationActions } from "../../actions/notifications/types"

type NotificationState = {
    wbsDeleteResult?: ResponseStatus
    userVerificationFinished: boolean
    registrationsLoadingFinished: boolean
    usersForWbsSaved?: ResponseStatus
}

export const notificationReducer = (
    state: NotificationState = { userVerificationFinished: false, registrationsLoadingFinished: false },
    action: NotificationActions
) => {
    switch (action.type) {
        case Constants.WBS_DELETE_RESULT: {
            return { ...state, wbsDeleteResult: action.payload }
        }
        case Constants.USER_VERIFICATION_FINISHED: {
            return { ...state, userVerificationFinished: action.payload }
        }
        case Constants.REGISTRATIONS_LOADING_FINISHED: {
            return { ...state, registrationsLoadingFinished: action.payload }
        }
        case Constants.USERS_FOR_WBS_SAVED: {
            return { ...state, usersForWbsSaved: action.payload }
        }

        default: { return state }
    }
}
