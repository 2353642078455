import * as Constants from "../../actions/settings/constants";
import { UserSettings } from "../../../types";
import { UserSettingsActions } from "../../actions/settings/types";

export const userSettingsReducer = (
  state: UserSettings = {
    darkMode: false,
  },
  action: UserSettingsActions
) => {
  switch (action.type) {
    case Constants.UPDATE_DARK_MODE: {
      return { ...state, darkMode: action.payload }
    }
    case Constants.UPDATE_ALL_USER_SETTINGS: {
      return { ...state, darkMode: action.payload.darkMode }
    }
    default: {
      return state;
    }
  }
};
