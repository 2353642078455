import { ProjectData, RegistrationData, WBSData } from "../state/types";
export * from './dateFormatters'
export * from './roleManagement'

//Utility functions

/** Update oldData array with new data: update existing values and insert new ones.
 * Used to update state.    
 */
export const updateRegistrations = (oldData: RegistrationData[], newData: RegistrationData[]): RegistrationData[] => {
    let result = oldData.slice();

    newData.forEach(element => result = result.filter(item => (item.id !== element.id)));    //remove all the existing data present in the new data
    result = [...result, ...newData];   //add new and updated data

    return result;
}

/** Set registrations to approved when their id us present in approved array
 * Approved array contains ids of approved registratsions.
 * Used to update state.
 */
export const updateApprovedRegistrations = (registrations: RegistrationData[], approved: number[]) => {
    let result = registrations.slice();

    approved.forEach(id => result.find(r => r.id === id)!.isApproved = true)

    return result
}


/** Update project with given Id with new wbs 
 * (wbs can be new or update of existing)
 */
export const mergeWBSForProject = (projects: ProjectData[], wbs: WBSData): ProjectData[] => {
    let result = projects.slice();

    let project = result.find(p => p.id === wbs.projectId)
    if (project) {
        //remove the wbs, if it exist
        project.WBS = project.WBS.filter(item => item.id !== wbs.id)
        //add new wbs to the project list
        project.WBS = [...project.WBS, wbs]
    }
    return result
}

export const deleteWbsFromProjects = (projects: ProjectData[], wbsId: number): ProjectData[] => {
    let result = projects.slice();

    //find the project that contained given WBS id
    let project = result.find(p => p.WBS.find(w => w.id === wbsId))

    if (project) {
        project.WBS = project.WBS.filter(w => w.id !== wbsId)   //remove it from the list
    }

    return result
}

/**
 * Get array of dates within the period specified by the input date.
 */
export const getPeriodDays = (date: Date): Date[] => {
    const days: Date[] = [];

    if (date.getDate() < 16) {
        for (let i = 1; i <= 15; i++) {
            days.push(new Date(date.getFullYear(), date.getMonth(), i));
        }
    } else {
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        for (let i = 16; i <= daysInMonth; i++) {
            days.push(new Date(date.getFullYear(), date.getMonth(), i));
        }
    }

    return days;
};

/**
 * Get sum of total hours for given set of registrations.
 */
export const getSumOfHours = (registrations: RegistrationData[]): number => {
    return registrations.reduce((h: number, i) => h + i.hours!, 0)
}

/**
 * Return hours formatted (rounded to 1 decimal)
 * @param hours 
 * @returns 
 */
export const formatHours = (hours: number): string => hours.toFixed(1)

/**
 * Get full date object for given day in the month & year of selected date
 */
export const getDateFromDay = (day: number, date: Date): Date => new Date(date.getFullYear(), date.getMonth(), day)

/**
 * Get authorization header with Bearer token
 * @param token
 * @returns 
 */
export const getAuthorizationHeader = (token: string) => {
    return {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
}

/**
 * Check if all registrations are approved
 * @param date 
 * @returns 
 */
export const allApproved = (registrations: RegistrationData[]): boolean =>
    registrations.length > 0 &&
    registrations.filter((r) => !r.isApproved).length === 0;

export const isWeekend = (date: Date): boolean => (date.getDay() === 6 || date.getDay() === 0)

export const isProduction = (): boolean => process.env.NODE_ENV === 'production'

/**
 * Filter registrations for a proper period, based on selectedDate
 */
export const periodFilterPredicate = (registrationDate: Date, selectedDate: Date): boolean => {
    return (selectedDate.getDate() < 16 ? registrationDate.getDate() < 16 : registrationDate.getDate() > 15)
        && (registrationDate.getMonth() === selectedDate.getMonth())
        && (registrationDate.getFullYear() === selectedDate.getFullYear())
}

/**
 * Filter registrations for given month, based on selectedDate
 * @param registrationDate 
 * @param selectedDate 
 */
export const monthFilterPredicate = (registrationDate: Date, selectedDate: Date): boolean => {
    return (registrationDate >= firstDayOfMonth(selectedDate)) && (registrationDate <= lastDayOfMonth(selectedDate))
}

/**
 * Filter registrations for given date, based on selectedDate
 * @param registrationDate 
 * @param selectedDate 
 * @returns 
 */
export const dayFilterPredicate = (registrationDate: Date, selectedDate: Date): boolean => {
    return ((registrationDate.getDate() === selectedDate.getDate())
        && (registrationDate.getMonth() === selectedDate.getMonth())
        && (registrationDate.getFullYear() === selectedDate.getFullYear()))
}

export const firstDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1)
export const endOfFirstPeriod = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 15)
export const startOfSecondPeriod = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 16)
export const lastDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0)

/**
 * Check if provided date is in current period
 * @param date 
 */
export const isInCurrentPeriod = (date: Date): boolean => {
    const now = new Date()
    if (now.getDate() <= 15) {
        return (date >= firstDayOfMonth(now) && date <= endOfFirstPeriod(now))
    } else {
        return (date >= startOfSecondPeriod(now) && date <= lastDayOfMonth(now))
    }
}

export const downloadFile = (data: string, fileName: string, contentType: string) => {
    const link = document.createElement('a');
    link.download = fileName;
    const blob = new Blob([data], { type: contentType });
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
}

/**
 * Return deep copy of an array
 */
export function nestedCopy<Type>(array: Type): Type {
    return JSON.parse(JSON.stringify(array));
}
