import { RegistrationData } from '../../../../state/types';
import { formatHours, isWeekend } from '../../../../utils'
import { weekendHighlight, approvedHighlight } from '../styles';
import { HourCellProps, HoursRowProps } from './types';
import { Link, TableCell, SxProps, Stack, Tooltip } from '@mui/material'
import { EditIcon } from '../../../../components/formElements/EditIcon';

export const getDummyRegistration = (modelRegistration: RegistrationData, date: Date): RegistrationData => {
    return {
        id: -1,
        projectId: modelRegistration.projectId,
        projectName: modelRegistration.projectName,
        date: date,
        wbsId: modelRegistration.wbsId,
        wbsName: modelRegistration.wbsName,
        wbsCode: modelRegistration.wbsCode,
        ticket: modelRegistration.ticket,
        activity: '',
        hours: 0,
        typeId: modelRegistration.typeId,
        typeName: modelRegistration.typeName,
        userId: modelRegistration.userId,
        isApproved: false
    }
}

const getCellStyleOfRegistrations = (registrations: RegistrationData[]): SxProps | null => {
    if (registrations.length === 0) {
        return null
    }

    if (registrations.every(registration => registration.isApproved)) {
        return approvedHighlight
    } else if (isWeekend(registrations[0].date)) {
        return weekendHighlight
    }

    return null
}

const getCellStyleOfRegistration = (registration: RegistrationData): SxProps | null => {
    if (registration.isApproved) {
        return approvedHighlight
    }

    return null
}

const displayValue = (input: number): string => input === 0 ? '...' : formatHours(input)

export const HourCell: React.FC<HourCellProps> = ({ registrations, onRegistrationClicked }) => {
    const cellColor = getCellStyleOfRegistrations(registrations)

    return (
        <TableCell align='right' sx={cellColor}>
            <Stack>
                {registrations.map(registration =>
                    <Tooltip key={registration.id} title={registration.activity} arrow>
                        <Link key={registration.id} color="secondary"
                            onClick={() => onRegistrationClicked!!(registration)}
                            sx={{ ...getCellStyleOfRegistration(registration), textAlign: "center", cursor: "pointer" }}>
                            {displayValue(registration.hours)}
                        </Link>
                    </Tooltip>
                )}
            </Stack>
        </TableCell>
    )
}

export const HoursRow: React.FC<HoursRowProps> = ({ dates, registrations, onRegistrationClicked, onRegistrationBulkEditClicked }) => {
    return <>
        <TableCell><EditIcon onClick={() => onRegistrationBulkEditClicked(registrations)} /></TableCell>
        {
            dates.map(date => {
                const registrationOnDay = registrations.filter(r => r.date.getDate() === date.getDate())
                if (registrationOnDay.length > 0) {
                    return <HourCell key={registrationOnDay[0].id} registrations={registrationOnDay} onRegistrationClicked={onRegistrationClicked} />
                }
                else {
                    return <HourCell key={date.getDate()} registrations={[getDummyRegistration(registrations[0], date)]} onRegistrationClicked={onRegistrationClicked} />
                }
            })
        }
    </>
}