import { AdminDataActions } from "../../actions/adminData/types"
import * as Constants from "../../actions/adminData/constants"
import { ProjectData, RegistrationData, UserData, WBSData } from "./../../../types"
import { deleteWbsFromProjects, mergeWBSForProject, updateApprovedRegistrations, updateRegistrations } from '../../../../utils'

type AdminState = {
    allUsers: UserData[],
    allProjects: ProjectData[],
    userRegistrations: RegistrationData[],
    userProjects: ProjectData[]
    usersForWbs: UserData[],
    wbsForUser: WBSData[],
}

export const adminDataReducer = (
    state: AdminState = { allUsers: [], allProjects: [], userRegistrations: [], userProjects: [], usersForWbs: [], wbsForUser: [] },
    action: AdminDataActions
): AdminState => {
    switch (action.type) {
        case Constants.SET_ALL_USERS: {
            return { ...state, allUsers: action.payload.users }
        }
        case Constants.SET_ALL_PROJECTS: {
            return { ...state, allProjects: action.payload.projects }
        }
        case Constants.SET_USER_REGISTRATIONS: {
            return { ...state, userRegistrations: action.payload.registrations }
        }
        case Constants.SET_USER_PROJECTS: {
            return { ...state, userProjects: action.payload.projects }
        }
        case Constants.UPDATE_APPROVED_STATUS: {
            return { ...state, userRegistrations: updateApprovedRegistrations(state.userRegistrations, action.payload.registrationIds) }
        }
        case Constants.UPDATE_USER_REGISTRATIONS: {
            let mergedRegistrations = updateRegistrations(state.userRegistrations, action.payload.registrations)
            return { ...state, userRegistrations: mergedRegistrations }
        }
        case Constants.DELETE_USER_REGISTRATION: {
            return { ...state, userRegistrations: state.userRegistrations.filter((registration) => registration.id !== action.payload.id) }
        }
        case Constants.UPDATE_WBS_FOR_PROJECT: {
            return { ...state, allProjects: mergeWBSForProject(state.allProjects, action.payload.wbs) }
        }
        case Constants.DELETE_WBS: {
            return { ...state, allProjects: deleteWbsFromProjects(state.allProjects, action.payload.id) }
        }
        case Constants.SET_USERS_FOR_WBS: {
            return { ...state, usersForWbs: action.payload.users }
        }
        case Constants.SET_WBS_FOR_USER: {
            return { ...state, wbsForUser: action.payload.wbs }
        }
        default: {
            return state
        }
    }
}