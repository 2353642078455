import { FC } from "react";
import { Autocomplete, Button, Stack, TextField, Tooltip } from '@mui/material';
import { WBSData } from "../../../../../state/types";
import { Formik, Form, Field, useField, FieldInputProps } from 'formik';
import * as Yup from 'yup';
import { useAppTranslationByLocation } from "../../../../../translations";
import { COMMON_LABELS_TRANSLATIONS, ERROR_MESSAGE_TRANSLATIONS, WBS_PROJECTEDITOR_TRANSLATIONS } from "../../../translations";
import { WBSDataForm, WbsEditorProps } from "../types";
import { TFunction } from "i18next";
import { ActiveSwitch } from "./ActiveSwitch";
import { useSelector } from "react-redux";
import { HATState } from "../../../../../state";

export const WBSEditor: FC<WbsEditorProps> = ({ wbs, onWbsFormSubmitted, onWbsDeleteClicked }) => {
    const { t } = useAppTranslationByLocation();

    const onSubmit = (wbsFormInput: WBSDataForm) => {
        const newWbs: WBSData = {
            id: wbs?.id ?? 0,
            name: wbsFormInput.name!,
            code: wbsFormInput.code!,
            active: wbsFormInput.active,
            projectId: wbsFormInput.projectId!
        }

        onWbsFormSubmitted(newWbs);
    };

    return (
        <Formik initialValues={mapToInitialValue(wbs)} onSubmit={onSubmit} enableReinitialize validationSchema={buildWBSValidationSchema(t)}>
            {({ errors, touched }) => (
                <Form>
                    <Stack spacing={2}>
                        <h2 style={{ marginLeft: 14 }}>{wbs != null ? `${t(COMMON_LABELS_TRANSLATIONS.EDIT)} ${wbs.name}` : `${t(WBS_PROJECTEDITOR_TRANSLATIONS.EDITOR_LABELS.ADD_NEW_WBS)}`}</h2>
                        {touched.projectId && errors.projectId && <p style={{ color: "#FF0000" }}>{errors.projectId}</p>}
                        <Field name="projectId" as={ProjectSelectWithAutoComplete} label={t(COMMON_LABELS_TRANSLATIONS.PROJECT)} />
                        {touched.name && errors.name && <p style={{ color: "#FF0000" }}>{errors.name}</p>}
                        <Field name="name" as={TextField} label={t(COMMON_LABELS_TRANSLATIONS.NAME)} />
                        {touched.code && errors.code && <p style={{ color: "#FF0000" }} >{errors.code}</p>}
                        <Field name="code" as={TextField} label={t(COMMON_LABELS_TRANSLATIONS.CODE)} />
                        {touched.active && errors.active && <p style={{ color: "#FF0000" }}>{errors.active}</p>}
                        <Field name="active" as={ActiveSwitch} />
                        <Button variant="contained" type="submit">{wbs != null ? `${t(COMMON_LABELS_TRANSLATIONS.UPDATE)} WBS` : `${t(COMMON_LABELS_TRANSLATIONS.CREATE)} WBS`}</Button>
                        {wbs != null &&
                            <Tooltip title={wbs.active && t(WBS_PROJECTEDITOR_TRANSLATIONS.DELETE_INFO)} placement="top" arrow>
                                <div>
                                    <Button style={{ width: '100%' }}
                                        disabled={wbs.active}
                                        variant="contained"
                                        type="button"
                                        color="error"
                                        onClick={() => onWbsDeleteClicked(wbs)}>
                                        {t(WBS_PROJECTEDITOR_TRANSLATIONS.DELETE_WBS)}
                                    </Button>
                                </div>
                            </Tooltip>
                        }
                    </Stack>
                </Form>
            )}
        </Formik>

    );
}

const mapToInitialValue = (wbs: WBSData | null): WBSDataForm => {
    return {
        id: wbs?.id ?? null,
        name: wbs?.name ?? '',
        code: wbs?.code ?? '',
        active: wbs?.active ?? true,
        projectId: wbs?.projectId ?? null
    }
}

const buildWBSValidationSchema = (t: TFunction<"hat-web", undefined, "hat-web">) => {
    const WBSFormValidationSchema: Yup.Schema<Omit<WBSData, "id">> = Yup.object().shape({
        name: Yup.string()
            .min(3, `${t(COMMON_LABELS_TRANSLATIONS.NAME)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_TOO_SHORT)}`)
            .max(255, `${t(COMMON_LABELS_TRANSLATIONS.NAME)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_TOO_LONG)}`)
            .required(`${t(COMMON_LABELS_TRANSLATIONS.NAME)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_REQUIRED)}`),
        code: Yup.string()
            .min(3, `${t(COMMON_LABELS_TRANSLATIONS.CODE)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_TOO_SHORT)}`)
            .max(255, `${t(COMMON_LABELS_TRANSLATIONS.CODE)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_TOO_LONG)}`)
            .required(`${t(COMMON_LABELS_TRANSLATIONS.CODE)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_REQUIRED)}`),
        active: Yup.bool()
            .required(),
        projectId: Yup.number()
            .required(`${t(COMMON_LABELS_TRANSLATIONS.PROJECT)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_REQUIRED)}`)

    });

    return WBSFormValidationSchema
}

const ProjectSelectWithAutoComplete: FC<FieldInputProps<number>> = ({ name, value }) => {
    const { setValue } = useField(name)[2];

    const projects = useSelector((state: HATState) => state.adminData.allProjects)

    return (
        <Autocomplete
            options={projects}
            value={projects.find(t => t.id === value) || null}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Project" />}
            onChange={(event, newValue) => {
                setValue(newValue?.id)
            }}
        />
    );
}