import {
    LoadKindDataViaSagaAction,
} from "./types"
import * as Constants from './constants'

export const loadKindDataViaSagaAction = (): LoadKindDataViaSagaAction => {
    return {
        payload: {},
        type: Constants.LOAD_KIND_DATA_SAGA
    }
}