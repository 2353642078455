import {
    SetAllUsersAction,
    SetUserRegistrationsAction,
    SetUserProjectsAction,
    UpdateApprovedStatus,
    UpdateUserRegistrations,
    DeleteUserRegistration,
    SetAllProjectsAction,
    UpdateWBSForProjectAction,
    DeleteWBSAction,
    SetUsersForWbsAction,
    SetWbsForUserAction,
} from "./types"
import * as Constants from './constants'
import { ProjectData, RegistrationData, UserData, WBSData } from '../../../types'

export const setAllUsersAction = ({ users }: { users: UserData[] }): SetAllUsersAction => {
    return {
        type: Constants.SET_ALL_USERS,
        payload: {
            users
        }
    }
}

export const setAllProjectsAction = (projects: ProjectData[]): SetAllProjectsAction => {
    return {
        type: Constants.SET_ALL_PROJECTS,
        payload: {
            projects
        }
    }
}

export const setUserRegistrationsAction = ({ registrations }: { registrations: RegistrationData[] }): SetUserRegistrationsAction => {
    return {
        type: Constants.SET_USER_REGISTRATIONS,
        payload: {
            registrations
        }
    }
}

export const setUserProjectsAction = ({ projects }: { projects: ProjectData[] }): SetUserProjectsAction => {
    return {
        type: Constants.SET_USER_PROJECTS,
        payload: {
            projects
        }
    }
}

export const updateApprovedStatus = ({ registrationIds }: { registrationIds: number[] }): UpdateApprovedStatus => {
    return {
        type: Constants.UPDATE_APPROVED_STATUS,
        payload: {
            registrationIds
        }
    }
}

export const updateUserRegistrations = ({ registrations }: { registrations: RegistrationData[] }): UpdateUserRegistrations => {
    return {
        type: Constants.UPDATE_USER_REGISTRATIONS,
        payload: {
            registrations
        }
    }
}

export const deleteUserRegistration = ({ id }: { id: number }): DeleteUserRegistration => {
    return {
        type: Constants.DELETE_USER_REGISTRATION,
        payload: {
            id
        }
    }
}

export const updateWBSForProjectAction = (data: { wbs: WBSData }): UpdateWBSForProjectAction => {
    return {
        type: Constants.UPDATE_WBS_FOR_PROJECT,
        payload: data
    }
}

export const deleteWbsAction = ({ id }: { id: number }): DeleteWBSAction => {
    return {
        type: Constants.DELETE_WBS,
        payload: {
            id
        }
    }
}

export const setUsersForWbsAction = ({ users }: { users: UserData[] }): SetUsersForWbsAction => {
    return {
        type: Constants.SET_USERS_FOR_WBS,
        payload: {
            users
        }
    }
}

export const setWbsForUserAction = ({ wbs }: { wbs: WBSData[] }): SetWbsForUserAction => {
    return {
        type: Constants.SET_WBS_FOR_USER,
        payload: {
            wbs
        }
    }
}