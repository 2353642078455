import { TextField, Autocomplete } from "@mui/material"
import { UserSelectProp } from "./types";
import { useAppTranslationByLocation } from "../../../../../translations";
import { USER_SELECT_LABELS } from "./translations"

export const UserSelect: React.FC<UserSelectProp> = ({ handleUserSelectClick, users }) => {
    const { t } = useAppTranslationByLocation()

    const handleChange = (event: any, newValue: { id: number, label: string } | null) => {
        if (newValue != null) handleUserSelectClick(users.find(u => u.id === newValue.id)!)
    }

    return (
        <Autocomplete
            sx={{ width: 300 }}
            options={users.map(u => ({ id: u.id, label: u.displayName }))}
            renderInput={(params) => <TextField {...params} size="small" label={t(USER_SELECT_LABELS.SELECT_USER)} />}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    )
}