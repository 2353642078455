import * as Types from "./types"
import * as Constants from './constants'
import { UserData } from "../../types"

export const validateCurrentUserViaSagaAction = (): Types.ValidateCurrentUserViaSagaAction => {
    return {
        payload: {},
        type: Constants.VALIDATE_CURRENT_USER_SAGA
    }
}

export const loadAllUsersViaSagaAction = (): Types.LoadAllUsersViaSagaAction => {
    return {
        payload: {},
        type: Constants.LOAD_ALL_USERS_SAGA
    }
}

export const updateUserViaSagaAction = (user: UserData): Types.UpdateUserViaSagaAction => {
    return {
        payload: user,
        type: Constants.UPDATE_USER_SAGA
    }
}

export const createUserViaSagaAction = (data: { user: UserData }): Types.CreateUserViaSagaAction => {
    return {
        payload: data,
        type: Constants.CREATE_USER_SAGA
    }
}

export const saveDarkModeSettingsViaSagaAction = (darkMode: boolean): Types.SaveDarkModeSettingsViaSagaAction => {
    return {
        payload: darkMode,
        type: Constants.SAVE_DARK_MODE_SETTINGS_SAGA
    }
}

export const loadCurrentUserSettingsViaSagaAction = (): Types.LoadCurrentUserSettingsSagaAction => {
    return {
        payload: {},
        type: Constants.LOAD_CURRENT_USER_SETTINGS_SAGA
    }
}