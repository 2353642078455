import * as Types from "./types"
import * as Constants from './constants'
import { WBSData } from "../../types"

export const loadUsersForWbsSagaAction = (data: { wbsId: number }): Types.LoadUsersForWbsSagaAction => {
    return {
        payload: data,
        type: Constants.LOAD_USERS_FOR_WBS_SAGA
    }
}

export const loadWbsForUserViaSagaAction = (data: { userId: number }): Types.LoadWbsForUserViaSagaAction => {
    return {
        payload: data,
        type: Constants.LOAD_WBS_FOR_USER_SAGA
    }
}

export const saveWBSViaSagaAction = (wbs: WBSData): Types.SaveWBSViaSagaAction => {
    return {
        payload: wbs,
        type: Constants.SAVE_WBS_SAGA
    }
}

export const deleteWbsViaSagaAction = (data: { id: number }): Types.DeleteWbsViaSagaAction => {
    return {
        payload: data,
        type: Constants.DELETE_WBS_SAGA
    }
}

export const saveUsersForWbsViaSagaAction = (data: { wbsId: number, userIds: number[] }): Types.SaveUsersForWbsViaSagaAction => {
    return {
        payload: data,
        type: Constants.SAVE_USERS_FOR_WBS_SAGA
    }
}

export const saveWbsForUserViaSagaAction = (data: { userId: number, wbsIds: number[] }): Types.SaveWbsForUserViaSagaAction => {
    return {
        payload: data,
        type: Constants.SAVE_WBS_FOR_USER_SAGA
    }
}