import { Box, CircularProgress } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { SideBarMenu } from '../../../components'
import { Login } from './Login'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { useEffect, useRef, useState } from 'react'
import { Authentication } from './Authentication'
import { LayoutProps } from './types'
import { useSelector } from 'react-redux'
import { HATState } from '../../../state'
import { NoAccess } from '../noAccess'

export const Layout: React.FC<LayoutProps> = ({ userInfo }) => {
    // use ref to store the timer id
    const refTimer = useRef<number | null>(null);
    // the last time timer was set
    const [, setLastTime] = useState<Date>(new Date());

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const isVerificationFinished = useSelector((state: HATState) => state.notifications.userVerificationFinished)

    useEffect(() => {
        //Set time to refresh the component. This will ensure refreshing the token if needed. (<Authentication/> takes care of this)
        refTimer.current = window.setTimeout(() => {
            setLastTime(new Date());
        }, 900_000);  // 15 minutes

        // Cleanup function to clear the timer when the component unmounts
        return () => {
            if (refTimer.current !== null) {
                window.clearTimeout(refTimer.current);
            }
        }
    })

    useEffect(() => {
        isLoading && userInfo.accessToken !== "" && isVerificationFinished && setIsLoading(false) //if the user is already authenticated (= has access token)
    }, [isLoading, userInfo, isVerificationFinished])

    return (
        <>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Authentication userInfo={userInfo} />
                {isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', pt: '100px' }}>
                        <CircularProgress />
                    </Box>
                    : userInfo.hasAccess ?
                        <>
                            <SideBarMenu role={userInfo.role} />
                            <Box
                                sx={{
                                    marginTop: "70px",
                                    display: "flex",
                                    flexGrow: 1,
                                    overflow: "auto"
                                }}
                            >
                                <Outlet />
                            </Box>
                        </>
                        :
                        <NoAccess />
                }
            </AuthenticatedTemplate>
        </>
    )
}