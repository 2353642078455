import { Modal, Box, Stack, Button } from "@mui/material"
import { FormModalProps } from "./types";
import { modalStyle } from "../../../../components/styling";
import { DayInputForm } from "../../../dayView/components";
import { DateSelect } from "../../../../components/formElements";
import { useEffect, useState } from "react";
import { useAppTranslationByLocation } from "../../../../translations";
import { FORM_MODAL_LABELS } from "./translations";

export const FormModal: React.FC<FormModalProps> = ({ open, onClose, registration, projects, kinds, defaultDate = new Date(), onFormSubmitted, onDelete }) => {
    const [selectedDate, setSelectedDate] = useState<Date>(defaultDate);
    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        registration ? setSelectedDate(registration.date) : setSelectedDate(defaultDate)
    }, [defaultDate, registration])

    const onDateChanged = (selectedDate: Date) => {
        setSelectedDate(selectedDate)
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Stack spacing={1}>
                    <DateSelect selectedDate={selectedDate} onDateChanged={onDateChanged} />
                    <DayInputForm kinds={kinds}
                        projects={projects}
                        selectedDate={selectedDate}
                        selectedRegistration={registration}
                        onFormSubmitted={onFormSubmitted}
                        showRecurrence={registration === null || registration.id < 0} />
                    <Button type="submit"
                        color="error"
                        variant="contained"
                        disabled={!((registration !== null) && registration.id > 0)}
                        onClick={() => onDelete(registration!)}>
                        {t(FORM_MODAL_LABELS.DELETE_BUTTON)}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}