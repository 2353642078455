import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { hatReducer } from './redux/reducers'
import { hatSagas } from './sagas'

const sagaMiddleware = createSagaMiddleware()
const store = configureStore({
    reducer: hatReducer,
    devTools: true,
    middleware: [sagaMiddleware],
})

sagaMiddleware.run(hatSagas)

export type HATState = ReturnType<typeof store.getState>
export type HATDispatch = typeof store.dispatch

export { store }