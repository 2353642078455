import * as React from "react";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Avatar, Tooltip, MenuItem, Switch, Link } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import { navigationTexts } from "./constants";
import { useAppTranslationByLocation } from '../../translations'
import { NavBarProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { userInfoUserNameSelector } from '../../state/selectors'
import { HATState } from "../../state";
import { saveDarkModeSettingsViaSagaAction } from '../../state/sagas/actions';

const getInitials = (name: string): string => `${name.split(' ')[1][0]}${name.split(' ')[0][0]}`

export const NavBar = ({ handleDrawer }: NavBarProps) => {
  const { t } = useAppTranslationByLocation()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const fullName = useSelector(userInfoUserNameSelector);

  const isDarkTheme = useSelector((state: HATState) => state.settings.darkMode)
  const dispatch = useDispatch()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDarkModeSwitch = () => {
    dispatch(saveDarkModeSettingsViaSagaAction(!isDarkTheme))    
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "#ff5800",
        border: "#bf3e11",
      }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{
            marginRight: 5,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {t(navigationTexts.siteName)}
        </Typography>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {fullName}
        </Typography>
        <Box>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar>{getInitials(fullName)}</Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleDarkModeSwitch}>
              <Switch checked={isDarkTheme} />
              Dark Mode
            </MenuItem>
            {navigationTexts.routes.map(({ name, path }) => (
              <MenuItem key={name} onClick={handleCloseUserMenu}>
                <Link href={t(path) ?? ""}>
                  <Typography textAlign="center">{t(name)}</Typography>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar >
  );
};
