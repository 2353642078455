const tabTitleBase = "app.adminPanel.tabTitles"
export const TAB_TITLES_TRANSLATIONS = {
    EDIT_WBS_AND_PROJECT: `${tabTitleBase}.editWBSandProjects`,
    EDIT_USER: `${tabTitleBase}.editUsers`,
    APPROVE_HOURS: `${tabTitleBase}.approveHours`,
    REPORTS: `${tabTitleBase}.reports`,
}

const wbsProjectEditorBase = "app.adminPanel.WBSProjectEditor"
export const WBS_PROJECTEDITOR_TRANSLATIONS = {
    CREATE_NEW_PROJECT_LABEL: `${wbsProjectEditorBase}.createNewProject`,
    CREATE_NEW_WBS_LABEL: `${wbsProjectEditorBase}.createNewWBS`,
    SEARCH_PROJECTS_LABEL: `${wbsProjectEditorBase}.searchProjects`,
    EDITOR_LABELS: {
        ADD_NEW_PROJECTS: `${wbsProjectEditorBase}.editorLabels.addNewProjects`,
        ADD_NEW_WBS: `${wbsProjectEditorBase}.editorLabels.addNewWBS`,
        IS_ACTIVE: `${wbsProjectEditorBase}.editorLabels.isActive`
    },
    WBS_SAVED: `${wbsProjectEditorBase}.wbsSaved`,
    PROJECT_SAVED: `${wbsProjectEditorBase}.projectSaved`,
    DUPLICATE_WBS: `${wbsProjectEditorBase}.duplicateWbs`,
    DELETE_WBS: `${wbsProjectEditorBase}.deleteWbs`,
    DELETE_INFO: `${wbsProjectEditorBase}.deleteInfo`,
    WBS_DELETED: `${wbsProjectEditorBase}.wbsDeleted`,
    WBS_DELETE_WARNING: `${wbsProjectEditorBase}.wbsDeleteWarning`,
    INTERNAL_ERROR: `${wbsProjectEditorBase}.internalError`,
    USERS_SAVED: `${wbsProjectEditorBase}.usersSaved`,
}

const labelsBase = "app.common.labels"
export const COMMON_LABELS_TRANSLATIONS = {
    INACTIVE: `${labelsBase}.inactive`,
    ACTIVE: `${labelsBase}.active`,
    EDIT: `${labelsBase}.edit`,
    NAME: `${labelsBase}.name`,
    USER: `${labelsBase}.user`,
    CODE: `${labelsBase}.code`,
    UPDATE: `${labelsBase}.update`,
    CREATE: `${labelsBase}.create`,
    PROJECT: `${labelsBase}.project`,
    DEACTIVATE: `${labelsBase}.deactivate`,
};

const errorMessagesBase = "app.common.errorMessages"
export const ERROR_MESSAGE_TRANSLATIONS = {
    IS_TOO_SHORT: `${errorMessagesBase}.isTooShort`,
    IS_TOO_LONG: `${errorMessagesBase}.isTooLong`,
    IS_REQUIRED: `${errorMessagesBase}.isRequired`,
};