import dayjs from "dayjs";

/** 
 * Format date to yyyy-mm-dd format.
 */
export const formatDate = (date: Date): string => dayjs(date).format('YYYY-MM-DD')

/**
 * Format date to YYYYMMDDHHMMSS timestamp
 * @param date 
 * @returns 
 */
export const formatDateFullTimestamp = (date: Date): string => dayjs(date).format('YYYYMMDDHHmmss')

/**
 * Get short day name: Mon - Sun
 * @param date 
 * @returns 
 */
export const getShortDayName = (date: Date): string => dayjs(date).format('ddd')