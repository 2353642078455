import { PaletteMode, createTheme } from "@mui/material";

let sizingOptions = {
  components: {
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiCheckbox: {
      defaultProps: {
        sx: { padding: '4px' },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px',
        }
      }
    },
    MuiListItemText: {
      defaultProps: {
        sx: { margin: '0px' },
      }
    }
  }
}

let lightOptions = {
  palette: {
    mode: "light" as PaletteMode,
    primary: {
      main: "#FF5800",
    },
    secondary: {
      main: "#000000",
    },
    success: {
      main: "#2e7d32",
      light: "#FFF2EB"
    },
    info: {
      main: "#0288d1",
      light: "#e4e4e4"
    }
  },
}

let darkOptions = {
  palette: {
    mode: "dark" as PaletteMode,
    primary: {
      main: "#FF5800",
    },
    secondary: {
      main: "#ffffff",
    },
    success: {
      main: "#2e7d32",
      light: "#4d4343"
    },
    info: {
      main: "#0288d1",
      light: "#2f2f2f"
    },
  },
}

export const hatLightTheme = createTheme(lightOptions, sizingOptions);

export const hatDarkTheme = createTheme(darkOptions, sizingOptions,);