import { HourDto, ImpersonationApi, RecurrenceDto } from "../../../apis/sms.web.api/generatedCode"
import { RecurrenceInfo, RegistrationData } from "../../state/types"
import { formatDate, getAuthorizationHeader } from "../../utils"
import { ILoggerService } from "../types"
import { mapRegistration, mapToChargeRecordDto, mapToRecurrenceDto } from "../utils"

export class ImpersonationService {
    _loggerService: ILoggerService
    _impersonationApi: ImpersonationApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._impersonationApi = new ImpersonationApi(undefined, basePath)
        this._token = token
    }

    getHoursOfUsersBetweenDates = async (userId: number, from: Date, to: Date): Promise<RegistrationData[]> => {
        try {
            const response = await this._impersonationApi.apiImpersonationGetHoursOfUserBetweenDatesGet(userId, from.toISOString(), to.toISOString(), getAuthorizationHeader(this._token))
            return response.data.map(item => mapRegistration(item));
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return []
        }
    }

    updateHoursForUser = async (hoursToUpdate: RegistrationData[]): Promise<RegistrationData[]> => {
        try {
            const mappedData: HourDto[] = hoursToUpdate.map(item => mapToChargeRecordDto(item))

            const response = await this._impersonationApi.apiImpersonationCreateOrUpdateHoursPost(mappedData, getAuthorizationHeader(this._token))
            return response.data.map(hour => mapRegistration(hour))
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return []
        }
    }

    deleteChargedHour = async (hourIdToDelete: number): Promise<void> => {
        try {
            await this._impersonationApi.apiImpersonationDeleteDelete(hourIdToDelete, getAuthorizationHeader(this._token))
        }
        catch (ex) {
            this._loggerService.logError(ex)
        }
    }

    getXmlExportForPeriodAndUser = async (dateInPeriod: Date, userId: number): Promise<string> => {
        try {
            const response = await this._impersonationApi.apiImpersonationGenerateMyTeXmlExportGet(formatDate(dateInPeriod), userId, getAuthorizationHeader(this._token))
            return response.data
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return ""
        }
    }

    createRecurrentHours = async (registration: RegistrationData, recurrence: RecurrenceInfo): Promise<RegistrationData[]> => {
        try {
            const mappedData: RecurrenceDto = mapToRecurrenceDto(registration, recurrence)
            const response = await this._impersonationApi.apiImpersonationCreateRecurrentHoursPost(mappedData, getAuthorizationHeader(this._token));
            return response.data.map(hourViewDTO => mapRegistration(hourViewDTO))
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return []
        }
    }
}