import { Modal } from "@mui/material"
import { Box } from "@mui/system"
import { modalStyle } from "../../../../../components/styling"
import { WbsEditModalProps } from "../types"
import { WBSEditor } from "./WBSEditor"


export const WbsEditModal: React.FC<WbsEditModalProps> = ({ open, onClose, wbs, onWbsFormSubmitted, onWbsDeleteClicked }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <WBSEditor wbs={wbs} onWbsFormSubmitted={onWbsFormSubmitted} onWbsDeleteClicked={onWbsDeleteClicked} />
            </Box>
        </Modal>
    )
}