import { DataActions } from "../../actions/data/types"
import * as Constants from "../../actions/data/constants"
import { RegistrationData, KindData, ProjectData, UserData } from "./../../../types"
import { updateRegistrations } from '../../../../utils'

type DataState = {
    registrations: RegistrationData[],
    kinds: KindData[],
    projects: ProjectData[],
    users: UserData[]
}

export const dataReducer = (
    state: DataState = { registrations: [], kinds: [], projects: [], users: [] },
    action: DataActions
) => {
    switch (action.type) {
        case Constants.DELETE_REGISTRATION: {
            return { ...state, registrations: state.registrations.filter((registration) => registration.id !== action.payload) }
        }
        case Constants.SET_REGISTRATIONS: {
            return { ...state, registrations: action.payload }
        }
        case Constants.UPDATE_REGISTRATIONS: {
            let mergedRegistrations = updateRegistrations(state.registrations, action.payload)
            return { ...state, registrations: mergedRegistrations }
        }
        case Constants.SET_KIND_DATA: {
            return { ...state, kinds: action.payload }
        }
        case Constants.SET_PROJECT_DATA: {
            return { ...state, projects: action.payload }
        }
        default: {
            return state
        }
    }
}