import { useEffect, useState } from 'react';
import { PeriodSelect } from '../../components'
import { HATState } from '../../state';
import { useSelector, useDispatch } from 'react-redux';
import {
    loadKindDataViaSagaAction,
    loadMonthRegistrationViaSagaAction,
    loadCurrentUserProjectsViaSagaAction,
    saveDayDataViaSagaAction,
    deleteRegistrationViaSagaAction,
    loadAllProjectDataViaSagaAction,
    createRecurrentRegistrationViaSagaAction,
} from '../../state/sagas/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAppTranslationByLocation } from '../../translations';
import { PERIOD_VIEW_LABELS } from './translations';
import {
    formatDate,
    formatDateFullTimestamp,
    downloadFile,
    allApproved,
    periodFilterPredicate,
} from '../../utils';
import { Stack, Button, Box } from '@mui/material';
import { hoursService } from '../../services';
import { userInfoTokenSelector } from '../../state/selectors';
import { RecurrenceInfo, RegistrationData } from '../../state/types';
import { Add, IosShareOutlined } from '@mui/icons-material';
import { PeriodOverviewWithModals } from './components';
import { kindsSelector, monthRegistrationsSelector, userProjectsSelector } from '../../state/selectors/data';
import { allProjectsSelector } from '../../state/selectors/adminData';
import { registrationsLoadingFinishedAction } from '../../state/redux/actions';

export const PeriodView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useAppTranslationByLocation();
    const token: string = useSelector(userInfoTokenSelector);

    const [selectedDate, setSelectedDate] = useState<Date>(
        Object.keys(params).length > 0 && params.selectedDate
            ? new Date(params.selectedDate)
            : new Date()
    );
    const [newRegistration, setNewRegistration] = useState<boolean>(false);
    const [registrationsLoading, setRegistrationsLoading] = useState<boolean>(true);

    const kinds = useSelector(kindsSelector)
    const allProjects = useSelector(allProjectsSelector)
    const userProjects = useSelector(userProjectsSelector)
    const registrations = useSelector((state: HATState) => monthRegistrationsSelector(state, selectedDate))
    const loadingFinished = useSelector((state: HATState) => state.notifications.registrationsLoadingFinished)

    useEffect(() => {
        //reload registrations when selected date is changed
        dispatch(loadMonthRegistrationViaSagaAction({ dateInPeriod: selectedDate }));
        setRegistrationsLoading(true);
    }, [dispatch, selectedDate]);

    useEffect(() => {
        if (loadingFinished) {
            setRegistrationsLoading(false)
            dispatch(registrationsLoadingFinishedAction(false))
        }
    }, [loadingFinished, dispatch])

    useEffect(() => {
        //load static form data at page load
        dispatch(loadAllProjectDataViaSagaAction());
        dispatch(loadCurrentUserProjectsViaSagaAction());
        dispatch(loadKindDataViaSagaAction());
    }, [dispatch]);

    const onDateChanged = (selectedDate: Date) => {
        setSelectedDate(selectedDate);

        //add/udpate date in url
        if (location.pathname.includes('/date/')) {
            navigate(
                location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1) +
                formatDate(selectedDate)
            );
        } else {
            navigate(location.pathname + '/date/' + formatDate(selectedDate));
        }
    };

    const onDownloadButtonClick = () => {
        hoursService(token)
            .getXmlExportForPeriod(selectedDate)
            .then((data) => downloadFile(data, `myte_export_${formatDateFullTimestamp(new Date())}.xml`, 'text/xml'));
    };

    const onFormModalSubmitted = (registration: RegistrationData, recurrence?: RecurrenceInfo): void => {
        if (recurrence) {
            dispatch(createRecurrentRegistrationViaSagaAction({ registration, recurrence }))
        } else {
            dispatch(saveDayDataViaSagaAction({ registrations: [registration] }));
        }
        setNewRegistration(false)
        setRegistrationsLoading(true);
    };

    const onFormModalClose = () => setNewRegistration(false)

    const onFormModalDelete = (registration: RegistrationData): void => {
        dispatch(deleteRegistrationViaSagaAction({ id: registration.id }));
        setRegistrationsLoading(true);
    };

    const onBulkChangeModalSubmitted = (registrations: RegistrationData[]): void => {
        dispatch(saveDayDataViaSagaAction({ registrations }));
        setRegistrationsLoading(true);
    }

    const onNewRegistrationClicked = () => {
        setNewRegistration(true)
    }

    return (
        <Stack spacing={1} padding={1} flexGrow={1}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <PeriodSelect onDateChanged={onDateChanged} selectedDate={selectedDate} />
                <Stack direction={'row'} spacing={1}>
                    <Button
                        variant="outlined"
                        onClick={onNewRegistrationClicked}
                        startIcon={<Add />}
                    >
                        {t(PERIOD_VIEW_LABELS.ADD_BUTTON)}
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={!allApproved(registrations.filter(r => periodFilterPredicate(r.date, selectedDate)))}
                        onClick={onDownloadButtonClick}
                        startIcon={<IosShareOutlined />}
                    >
                        {t(PERIOD_VIEW_LABELS.EXPORT_MYTE_BUTTON)}
                    </Button>
                </Stack>
            </Box>
            <PeriodOverviewWithModals
                selectedDate={selectedDate}
                allProjects={allProjects}
                userProjects={userProjects}
                kinds={kinds}
                registrations={registrations}
                newRegistration={newRegistration}
                isLoading={registrationsLoading}
                onFormModalSubmitted={onFormModalSubmitted}
                onFormModalDelete={onFormModalDelete}
                onFormModalClose={onFormModalClose}
                onBulkChangeModalSubmitted={onBulkChangeModalSubmitted}
            />
        </Stack>

    );
};
