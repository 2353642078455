import { Button, TableCell } from "@mui/material";
import { RegistrationData } from "../../../../state/types";
import { formatHours } from "../../../../utils";

type DayRegistrationProps = {
    registration: RegistrationData,
    onDeleteButtonClicked: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void
}

export const DayRegistration: React.FC<DayRegistrationProps> = ({ registration, onDeleteButtonClicked }) => {


    return (
        <>
            <TableCell>{registration.projectName}</TableCell>
            <TableCell>{`${registration.wbsCode} - ${registration.wbsName}`}</TableCell>
            <TableCell>{registration.typeName}</TableCell>
            <TableCell>{registration.ticket}</TableCell>
            <TableCell align="right">{formatHours(registration.hours)}</TableCell>
            <TableCell><Button variant="outlined" onClick={(e) => onDeleteButtonClicked(e, registration.id)}>X</Button></TableCell>
        </>
    );
}