import {
    DeleteRegistrationAction,
    SetProjectsAction,
    SetRegistrationsAction,
    UpdateRegistrationsAction,
    SetKindsAction,
} from "./types"
import * as Constants from './constants'
import { RegistrationData, KindData, ProjectData } from '../../../types'

export const deleteRegistrationAction = (value: number): DeleteRegistrationAction => {
    return {
        type: Constants.DELETE_REGISTRATION,
        payload: value
    }
}

export const setRegistrationsAction = (data: RegistrationData[]): SetRegistrationsAction => {
    return {
        type: Constants.SET_REGISTRATIONS,
        payload: data
    }
}

export const updateRegistrationsAction = (data: RegistrationData[]): UpdateRegistrationsAction => {
    return {
        type: Constants.UPDATE_REGISTRATIONS,
        payload: data
    }
}

export const setKindsAction = (data: KindData[]): SetKindsAction => {
    return {
        type: Constants.SET_KIND_DATA,
        payload: data
    }
}

export const setProjectsAction = (data: ProjectData[]): SetProjectsAction => {
    return {
        type: Constants.SET_PROJECT_DATA,
        payload: data
    }
}