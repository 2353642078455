import { Button, Stack, Typography, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { AssignmentProps, ListItem, ListContainer } from "./types";
import { FilteredList } from "./components/FilteredList";
import { useEffect, useRef, useState } from "react";
import { useAppTranslationByLocation } from "../../translations";
import { ASSIGNMENT_LABELS } from "./translations";
import { Item } from "../styling";
import { nestedCopy } from "../../utils";

const removeItem = (data: ListContainer[], item: ListItem): { newData: ListContainer[], removedItem: ListContainer } => {
    var copyData = [...data]

    //find parent node
    var parent = copyData.find(c => c.items.find(i => i.id === item.id))

    if (parent) {
        //remove item from parent
        parent!.items = parent!.items.filter(i => i.id !== item.id)

        //check if parent is empty
        if (parent!.items.length === 0) copyData = copyData.filter(c => c.id !== parent!.id)

        //return item
        return { newData: copyData, removedItem: { id: parent!.id, name: parent!.name, items: [item] } }
    }
    else {
        //parent was not found = item to be removed is not in original data, return the original data without change
        return { newData: copyData, removedItem: { id: 0, name: "", items: [] } }
    }
}

const addItem = (data: ListContainer[], item: ListContainer): ListContainer[] => {
    var copyData = [...data]
    //check if parent exists
    var container = copyData.find(c => c.id === item.id)

    if (container) {
        //add item to existing container
        container.items = [...container.items, ...item.items].sort((i1, i2) => i1.value.localeCompare(i2.value))
    }
    else {
        //add container with item
        copyData = [...copyData, item].sort((i1, i2) => i1.name.localeCompare(i2.name))
    }

    return copyData
}

/**
 * Component to allow easy un/assigning of items.
 * 
 * The Items can be of 2 types:
 *  - plain arrays of `ListItem` (provided in variables `allItems` and `selectedItems`)
 *  - nested items provided as array of `ListContainer` (provided in variables `sellContainerItems`, `selectedContainerItems`)
 * Only one the these 2 sets of parameters should be used.
 * @param param0 
 * @returns 
 */
export const Assignment: React.FC<AssignmentProps> = ({ allItems, selectedItems, allContainerItems, selectedContainerItems, onItemsSelectionFinished }) => {
    const localAllContainerItems = useRef(allContainerItems?.filter(c => c.items.length > 0) ?? [])
    const showSimpleLists = useRef(false)

    const [localSelectedItems, setLocalSelectedItems] = useState<ListContainer[]>([])
    const [localAvailableItems, setLocalAvailableItems] = useState<ListContainer[]>([])
    const [isChanged, setIsChanged] = useState<boolean>(false)

    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        var localSelectedContainerItems = selectedContainerItems ?? []
        if (allItems && selectedItems) {
            //place the plain items into internal container
            localAllContainerItems.current = [{ id: 0, name: "Container", items: allItems }]
            localSelectedContainerItems = [{ id: 0, name: "Container", items: selectedItems }]
            showSimpleLists.current = true
        }

        setLocalSelectedItems(localSelectedContainerItems.sort((i1, i2) => i1.name.localeCompare(i2.name)))

        var filteredArray = nestedCopy<ListContainer[]>(localAllContainerItems.current)
        localSelectedContainerItems.forEach(c => c.items.forEach(i => removeItem(filteredArray, i)))  //remove items
        filteredArray.forEach(c => {
            if (c.items.length === 0) filteredArray = filteredArray.filter(c2 => c2.id !== c.id)      //remove empty containters
        })
        setLocalAvailableItems(filteredArray.sort((i1, i2) => i1.name.localeCompare(i2.name)))

        setIsChanged(false)
    }, [selectedContainerItems, allContainerItems, allItems, selectedItems])

    const handleAddItemClick = (item: ListItem) => {
        const { newData, removedItem } = removeItem(localAvailableItems, item)
        setLocalAvailableItems(newData)
        const newData2 = addItem(localSelectedItems, removedItem)
        setLocalSelectedItems(newData2)
        setIsChanged(true)
    }

    const handleRemoveItemClick = (item: ListItem) => {
        const { newData, removedItem } = removeItem(localSelectedItems, item)
        setLocalSelectedItems(newData)
        const newData2 = addItem(localAvailableItems, removedItem)
        setLocalAvailableItems(newData2)
        setIsChanged(true)
    }

    const handleAddAllItemsClick = () => {
        setLocalAvailableItems([])
        setLocalSelectedItems(nestedCopy<ListContainer[]>(localAllContainerItems.current).sort((i1, i2) => i1.name.localeCompare(i2.name)))
        setIsChanged(true)
    }

    const handleRemoveAllItemsClick = () => {
        setLocalSelectedItems([])
        setLocalAvailableItems(nestedCopy<ListContainer[]>(localAllContainerItems.current).sort((i1, i2) => i1.name.localeCompare(i2.name)))
        setIsChanged(true)
    }

    const handleSaveButtonClick = () => {
        onItemsSelectionFinished(localSelectedItems.reduce((resultList: ListItem[], c) => resultList.concat(c.items, []), []))
        setIsChanged(false)
    }


    return (
        <Stack spacing={2} height={'100%'}>
            <Grid container rowSpacing={2} sx={{ height: '100%' }}>
                <Grid xs={5}>
                    <Item style={{ height: '100%' }}>
                        <Stack spacing={2} height={'100%'}>
                            <Typography variant="h6" align={"center"}>{t(ASSIGNMENT_LABELS.AVAILABLE)}</Typography>
                            {showSimpleLists.current ?
                                <FilteredList listItems={localAvailableItems.flatMap(c => c.items)} onItemSelected={handleAddItemClick} />
                                :
                                <FilteredList containerItems={localAvailableItems} onItemSelected={handleAddItemClick} />
                            }
                        </Stack>
                    </Item>
                </Grid>
                <Grid container direction={"column"} padding={2} margin={'auto'} xs={2}>
                    <Grid>
                        <Tooltip title={t(ASSIGNMENT_LABELS.ADD_ALL)} placement="top" arrow>
                            <Button variant="contained" fullWidth onClick={handleAddAllItemsClick}>{'>>>'}</Button>
                        </Tooltip>
                    </Grid>
                    <Grid>
                        <Tooltip title={t(ASSIGNMENT_LABELS.REMOVE_ALL)} placement="bottom" arrow>
                            <Button variant="contained" fullWidth onClick={handleRemoveAllItemsClick}>{'<<<'}</Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid xs={5}>
                    <Item style={{ height: '100%' }}>
                        <Stack spacing={2} height={'100%'}>
                            <Typography variant="h6" align={"center"}>{t(ASSIGNMENT_LABELS.ASSIGNED)}</Typography>
                            {showSimpleLists.current ?
                                <FilteredList listItems={localSelectedItems.flatMap(c => c.items)} onItemSelected={handleRemoveItemClick} />
                                :
                                <FilteredList containerItems={localSelectedItems} onItemSelected={handleRemoveItemClick} />
                            }
                        </Stack>
                    </Item>
                </Grid>
            </Grid>
            <Button variant="contained" fullWidth disabled={!isChanged} onClick={handleSaveButtonClick}>{t(ASSIGNMENT_LABELS.SAVE)}</Button>
        </Stack>
    )
}