import { call, put, select } from 'redux-saga/effects'
import {
    setAllUserSettingsAction,
    setAllUsersAction,
    setDarkModeAction,
    setUserValidationResultAction,
    userVerificationFinishedAction,
} from '../../redux/actions'
import { userInfoTokenSelector, userSettingsSelector } from '../../selectors'
import { userService } from '../../../services'
import { consoleLoggerServiceFactory } from '../../../services'
import { UserData, UserSettings } from '../../types'
import { CreateUserViaSagaAction, SaveDarkModeSettingsViaSagaAction, UpdateUserViaSagaAction } from './types'
import { loadAllUsersViaSagaAction } from './actions'

export function* validateCurrentUserSaga() {
    const token: string = yield select(userInfoTokenSelector)
    const result: boolean = yield call(userService(token).ValidateUser)
    consoleLoggerServiceFactory().logMessage("validateCurrentUserSaga: ", result)

    yield put(setUserValidationResultAction({ hasAccess: result }));
    yield put(userVerificationFinishedAction(true));
}

export function* loadAllUsersViaSaga() {
    const token: string = yield select(userInfoTokenSelector)
    const users: UserData[] = yield call(userService(token).getUsers);
    consoleLoggerServiceFactory().logMessage("loadAllUsersViaSaga: ", users)

    yield put(setAllUsersAction({ users }));
}

export function* updateUserSaga(action: UpdateUserViaSagaAction) {
    const token: string = yield select(userInfoTokenSelector)

    yield call(userService(token).UpdateUser, action.payload)

    yield put(loadAllUsersViaSagaAction());
}

export function* createUserViaSaga(action: CreateUserViaSagaAction) {
    const token: string = yield select(userInfoTokenSelector)
    yield call(userService(token).CreateUser, action.payload.user);

    consoleLoggerServiceFactory().logMessage("createUserViaSaga: ", action.payload.user)
    yield put(loadAllUsersViaSagaAction());
}

export function* saveDarkModeViaSaga(action: SaveDarkModeSettingsViaSagaAction) {
    const token: string = yield select(userInfoTokenSelector)
    const userSettings: UserSettings = yield select(userSettingsSelector)
    console.log("Current user settings: ", userSettings)
    userSettings.darkMode = action.payload

    yield call(userService(token).updateUserSettings, userSettings)

    yield put(setDarkModeAction(action.payload))
}

export function* loadCurrentUserSettingsViaSaga() {
    const token: string = yield select(userInfoTokenSelector)
    const data: UserSettings = yield call(userService(token).getUserSettings)
    console.log("loadUserSettingsViaSaga: ", data)

    yield put(setAllUserSettingsAction(data))
}