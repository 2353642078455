import { Button, Grid, Stack } from '@mui/material';
import { DayRegistrationBox, DayInputForm } from "./components"
import { DateSelect } from '../../components/formElements';
import { Item } from '../../components/styling';
import { HATState } from '../../state';
import { useSelector, useDispatch } from 'react-redux'
import {
    loadDayDataViaSagaAction,
    loadKindDataViaSagaAction,
    saveDayDataViaSagaAction,
    deleteRegistrationViaSagaAction,
} from '../../state/sagas/actions';
import { RegistrationData } from "../../state/types";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadCurrentUserProjectsViaSagaAction } from '../../state/sagas/actions';
import { DAY_VIEW_LABELS } from './translations';
import { useAppTranslationByLocation } from '../../translations';
import { kindsSelector, dayRegistrationsSelector, userProjectsSelector } from '../../state/selectors/data';

export const DayView = () => {
    const params = useParams();
    const [selectedDate, setSelectedDate] = useState<Date>((Object.keys(params).length > 0 && params.selectedDate) ? new Date(params.selectedDate) : new Date());
    const [selectedRegistration, setSelectedRegistration] = useState<RegistrationData | null>(null)

    const { t } = useAppTranslationByLocation();

    const registrations = useSelector((state: HATState) => dayRegistrationsSelector(state, selectedDate))
    const kinds = useSelector(kindsSelector)
    const projects = useSelector(userProjectsSelector)

    const dispatch = useDispatch();

    useEffect(() => {
        //reload registrations when selected date is changed
        dispatch(loadDayDataViaSagaAction({ date: selectedDate }));
    }, [dispatch, selectedDate]);

    useEffect(() => {
        //load static form data at page load
        dispatch(loadKindDataViaSagaAction());
        dispatch(loadCurrentUserProjectsViaSagaAction());
    }, [dispatch])

    const onDateChanged = (selectedDate: Date) => {
        setSelectedDate(selectedDate);
        setSelectedRegistration(null);
    }

    const onDeleteButtonClicked = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
        event.stopPropagation();
        dispatch(deleteRegistrationViaSagaAction({ id }));
        selectedRegistration && id === selectedRegistration.id && setSelectedRegistration(null)
    }

    const onFormSubmitted = (data: RegistrationData) => {
        dispatch(saveDayDataViaSagaAction({ registrations: [data] }));
        setSelectedRegistration(null);
    }

    const onRegistrationSelected = (data: RegistrationData) => {
        setSelectedRegistration(data);
    }

    const onAddNewButtonClicked = () => {
        setSelectedRegistration(null);
    }

    return (
        <Grid container spacing={2} padding={2}>
            <Grid item xs={6}>
                <Item>
                    <Stack spacing={2} sx={{ mt: 1 }}>
                        <DateSelect selectedDate={selectedDate} onDateChanged={onDateChanged} />
                        <DayRegistrationBox
                            registrations={registrations.sort((a, b) => a.id - b.id)}
                            onDeleteButtonClicked={onDeleteButtonClicked}
                            onRegistrationSelected={onRegistrationSelected} />
                        <Button type="button" variant="contained" disabled={selectedRegistration === null} onClick={onAddNewButtonClicked}>{t(DAY_VIEW_LABELS.ADD_NEW)}</Button>
                    </Stack>
                </Item>
            </Grid>
            <Grid item xs={6}>
                <Item>
                    <DayInputForm kinds={kinds}
                        projects={projects}
                        onFormSubmitted={onFormSubmitted}
                        selectedDate={selectedDate}
                        selectedRegistration={selectedRegistration} />
                </Item>
            </Grid>
        </Grid>
    );
};
