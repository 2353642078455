import * as Types from './types'
import * as Constants from './constants'
import { ProjectData } from '../../types'

export const loadCurrentUserProjectsViaSagaAction = (): Types.LoadCurrentUserProjectsViaSagaAction => {
    return {
        payload: {},
        type: Constants.LOAD_CURRENT_USER_PROJECTS_SAGA
    }
}

export const loadAllProjectDataViaSagaAction = (): Types.LoadAllProjectDataViaSagaAction => {
    return {
        payload: {},
        type: Constants.LOAD_ALL_PROJECTS_DATA_SAGA
    }
}

export const createOrUpdateProjectViaSagaAction = (project: ProjectData): Types.CreateOrUpdateProjectViaSagaAction => {
    return {
        payload: project,
        type: Constants.CREATE_OR_UPDATE_PROJECT_SAGA
    }
}

export const loadUserProjectsViaSagaAction = (data: { userId: number }): Types.LoadUserProjectsViaSagaAction => {
    return {
        payload: data,
        type: Constants.LOAD_USER_PROJECTS_SAGA
    }
}