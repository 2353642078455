import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { LoginView } from "./login"
import { HomeView } from "./home";
import { DayView } from "./dayView";
import { PeriodView } from "./periodView";
import { AdminView } from "./adminView"
import { Layout, NoAccess } from "./components";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../state/selectors";
import { isAdmin, isProduction } from "../utils";

export const AppRoutes = () => {
  const userInfo = useSelector(userInfoSelector);

  const AuthorizedRoute = () => {
    if (!userInfo.hasAccess) {
      return <Navigate to="/noAccess" replace />;
    }
    return <Outlet />;
  }

  const AdminRoute = () => {
    if (!isAdmin(userInfo.role)) {
      return <Navigate to="/" replace />
    }
    return <Outlet />;
  }

  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<Layout userInfo={userInfo} />} >
          <Route path={'/login'} element={<LoginView />} />
          <Route element={<AuthorizedRoute />}>
            <Route index element={<Navigate to='/periodView' />} />
            {!isProduction() && <Route path={'/homeView'} element={<HomeView />} />}
            <Route path={'/dayView'} element={<DayView />} />
            <Route path={'/dayView/date/:selectedDate'} element={<DayView />} />
            <Route path={'/periodView'} element={<PeriodView />} />
            <Route path={'/periodView/date/:selectedDate'} element={<PeriodView />} />
            <Route element={<AdminRoute />}>
              <Route path={'/adminView'} element={<AdminView />} />
            </Route>
          </Route>
          <Route path={'*'} element={<Navigate to={'/'} />} />
        </Route>
        <Route path={'/noAccess'} element={<NoAccess />} />
      </Routes>
    </Router>
  );
};
