import { Box, CircularProgress, Modal, Stack } from "@mui/material"
import { LoadingModalProps } from "./types"
import { modalStyle } from "../../../../components/styling";
import { useCallback, useEffect, useRef, useState } from "react";

export const LoadingModal: React.FC<LoadingModalProps> = ({ open, onClose }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const timerId = useRef<number | null>(null);

    const onModalClose = useCallback(() => {
        setModalOpen(false)
        onClose()
    }, [onClose])

    useEffect(() => {
        if (open) {
            timerId.current = window.setTimeout(() => setModalOpen(true), 500)
        }
        else {
            onModalClose()
        }

        // Cleanup function to clear the timer when the component unmounts
        return () => {
            if (timerId.current !== null) {
                window.clearTimeout(timerId.current);
            }
        }
    }, [open, onModalClose])

    return (
        <Modal open={modalOpen} onClose={onModalClose}>
            <Box sx={modalStyle} >
                <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-around"}>
                    <CircularProgress /> <span>Loading.. / Updating..</span>
                </Stack>
            </Box>
        </Modal>
    )
}