export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS'
export const SET_USER_REGISTRATIONS = 'SET_USER_REGISTRATIONS'
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS'
export const UPDATE_APPROVED_STATUS = 'UPDATE_APPROVED_STATUS'
export const UPDATE_USER_REGISTRATIONS = 'UPDATE_USER_REGISTRATIONS'
export const DELETE_USER_REGISTRATION = 'DELETE_USER_REGISTRATION'
export const LOAD_USERS_WITH_WBS = 'LOAD_USERS_WITH_WBS'
export const UPDATE_WBS_FOR_PROJECT = 'UPDATE_WBS_FOR_PROJECT'
export const DELETE_WBS = 'DELETE_WBS'
export const SET_USERS_FOR_WBS = 'SET_USERS_FOR_WBS'
export const SET_WBS_FOR_USER = 'SET_WBS_FOR_USER'