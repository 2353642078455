import { ProjectData, WBSData } from "../../../../state/types";

export enum WbsClickType {
    SHOW,
    EDIT
}

export type ProjectSelectProps = {
    projects: ProjectData[],
    onProjectSelected: (project: ProjectData) => void,
    onWbsSelected: (wbs: WBSData, type: WbsClickType) => void,
}

export type ProjectEditorProps = {
    project: ProjectData | null,
    onProjectFormSubmitted: (project: ProjectData) => void,
}

export type ProjectEditModalProps = ProjectEditorProps & {
    open: boolean,
    onClose: () => void
}

export type WbsEditModalProps = WbsEditorProps & {
    open: boolean,
    onClose: () => void
}

export type ProjectDataForm = {
    id?: number,
    name?: string,
    active: boolean,
}

export type WbsEditorProps = {
    wbs: WBSData | null,
    onWbsFormSubmitted: (wbs: WBSData) => void,
    onWbsDeleteClicked: (wbs: WBSData) => void,
}

export type WBSDataForm = {
    id: number | null,
    active: boolean,
    name: string | null,
    code: string | null,
    projectId: number | null,
}