import { useState, FC, useEffect } from 'react';
import { Box, Button, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ProjectData, UserData, WBSData } from '../../../../state/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUserViaSagaAction,
    updateUserViaSagaAction,
    loadAllProjectDataViaSagaAction,
    loadWbsForUserViaSagaAction,
    saveWbsForUserViaSagaAction,
    loadAllUsersViaSagaAction
} from '../../../../state/sagas/actions';
import { allProjectsSelector, allUsersSelector, userWbsSelector } from '../../../../state/selectors/adminData';
import { UserEditModal, UserInfoDisplay } from './components';
import { Assignment } from '../../../../components';
import { ListContainer, ListItem } from '../../../../components/assignment/types';
import { FilteredList } from '../../../../components/assignment/components/FilteredList';
import { useAppTranslationByLocation } from '../../../../translations';
import { USER_EDITOR_LABELS } from './translations';
import { Item } from '../../../../components/styling';
import { nestedCopy } from '../../../../utils';

const mapWbsToListItem = (wbs: WBSData): ListItem => ({ id: wbs.id, value: `${wbs.name} (${wbs.code})` })
const mapProjectToListContainer = (project: ProjectData): ListContainer =>
    ({ id: project.id, name: project.name, items: project.WBS.map(mapWbsToListItem) })

const mapWbsToListContainer = (wbsList: WBSData[], allProjects: ProjectData[]): ListContainer[] => {
    var output: ListContainer[] = []

    wbsList.forEach(wbs => {
        const project = allProjects.find(p => p.WBS.find(w => w.id === wbs.id))
        const outputContainer = output.find(c => c.id === project!.id)
        if (outputContainer) {
            outputContainer.items = [...outputContainer.items, mapWbsToListItem(wbs)].sort((i1, i2) => i1.value.localeCompare(i2.value))
        } else {
            output = [...output, { id: project!.id, name: project!.name, items: [mapWbsToListItem(wbs)] }].sort((i1, i2) => i1.name.localeCompare(i2.name))
        }
    })

    return output
}

const filterActiveOnly = (projectList: ProjectData[]): ProjectData[] => {
    var output = nestedCopy<ProjectData[]>(projectList).filter(p => p.active)
    output.forEach(p => p.WBS = p.WBS.filter(w => w.active))
    return output
}

export const UserEditorView: FC = () => {
    const [selectedUser, setSelectedUser] = useState<UserData | undefined>(undefined);
    const [userModalOpen, setUserModalOpen] = useState<boolean>(false)
    const [showActiveUsers, setShowActiveUsers] = useState<boolean>(true)

    const { t } = useAppTranslationByLocation();

    const dispatch = useDispatch()
    const allUsers = useSelector(allUsersSelector)
    const allProjects = useSelector(allProjectsSelector)
    const userWbs = useSelector(userWbsSelector)

    useEffect(() => {
        dispatch(loadAllProjectDataViaSagaAction())
        dispatch(loadAllUsersViaSagaAction());
    }, [dispatch])

    const handleUserModalClose = () => setUserModalOpen(false)

    const handleSelectUser = (item: ListItem) => {
        setSelectedUser(allUsers.find(u => u.id === item.id));
        //load WBS for user
        dispatch(loadWbsForUserViaSagaAction({ userId: item.id }))
    }

    const handleUserFormSubmitted = (user: UserData) => {
        if (user.id === 0) {
            dispatch(createUserViaSagaAction({ user }))
        } else {
            dispatch(updateUserViaSagaAction(user))
            setSelectedUser(user)
        }
        setUserModalOpen(false)
    }

    const handleNewUserClick = () => {
        setSelectedUser(undefined)
        setUserModalOpen(true)
    }

    const handleEditUserClick = () => setUserModalOpen(true)

    function handleAssignmentSave(items: ListItem[]): void {
        dispatch(saveWbsForUserViaSagaAction({ userId: selectedUser!.id, wbsIds: items.map(i => i.id) }))
    }

    const hadleActiveSwitchClick = () => setShowActiveUsers(!showActiveUsers)

    return (
        <>
            <Grid container spacing={2} padding={1} width={'100%'}>
                <Grid xs={4}>
                    <Item style={{ height: '100%' }}>
                        <Stack spacing={1} sx={{ height: "100%" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <FilteredList
                                    listItems={allUsers.filter(u => u.isActive === showActiveUsers).map(u => ({ id: u.id, value: u.displayName }))}
                                    onItemSelected={handleSelectUser} />
                            </Box>
                            <FormGroup row>
                                <FormControlLabel
                                    label={t(USER_EDITOR_LABELS.ACTIVE_USERS)}
                                    control={<Switch checked={showActiveUsers} onChange={hadleActiveSwitchClick} />}
                                    labelPlacement='start' />
                            </FormGroup>
                            <Button variant="contained" fullWidth onClick={handleNewUserClick}>{t(USER_EDITOR_LABELS.CREATE_NEW_USER)}</Button>
                        </Stack>
                    </Item>
                </Grid>
                <Grid xs={8}>
                    <Item style={{ height: '100%' }}>
                        {selectedUser &&
                            <Stack spacing={1} direction={'column'} sx={{ height: '100%' }}>
                                <UserInfoDisplay user={selectedUser} onEditIconClick={handleEditUserClick} />
                                <Assignment
                                    allContainerItems={filterActiveOnly(allProjects).map(mapProjectToListContainer)}
                                    selectedContainerItems={mapWbsToListContainer(userWbs, allProjects)}
                                    onItemsSelectionFinished={handleAssignmentSave} />
                            </Stack>
                        }
                    </Item>
                </Grid>
            </Grid>
            <UserEditModal open={userModalOpen} onClose={handleUserModalClose} user={selectedUser} onUserFormSubmitted={handleUserFormSubmitted} />
        </>
    );
};