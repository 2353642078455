import { RegistrationData } from "../../../../state/types";
import { formatHours, getPeriodDays, getSumOfHours } from '../../../../utils'
import { ProjectRowProps } from './types';
import { TableCell, TableRow } from '@mui/material';
import { HoursRow } from '../hoursRow/HoursRow';

export const ProjectRow: React.FC<ProjectRowProps> = ({ project, registrations, totalHours,
    onRegistrationClicked = undefined, onRegistrationBulkEditClicked }) => {

    //Get list of distinct WBS id's
    const getWBSIds = (): number[] => {
        return [...new Set(registrations.map(r => r.wbsId))]
    }

    //Get list of distinct ticket numbers
    const getTicketNumbers = (registrations: RegistrationData[]): string[] => {
        return [...new Set(registrations.sort((a, b) => a.ticket.localeCompare(b.ticket)).map(r => r.ticket))]
    }

    //Get list of distinct kind id's
    const getKinds = (registrations: RegistrationData[]): number[] => {
        return [...new Set(registrations.sort((a, b) => a.typeName.localeCompare(b.typeName)).map(r => r.typeId))]
    }

    //Helper functions to decide, if the value shoud be displayed in the row or not
    let firstRow: boolean = true
    let currentWBSId: number = 0

    const showWBSValue = (WBSId: number): boolean => {
        if (currentWBSId !== WBSId) {
            currentWBSId = WBSId
            return true
        }
        return false
    }

    const showProject = (): boolean => {
        if (firstRow) {
            firstRow = false
            return true
        }
        return false
    }

    if (project) {
        return (
            <>
                {getWBSIds().map(wbs => {
                    let WBSRegistrations = registrations.filter(r => r.wbsId === wbs)

                    return getKinds(WBSRegistrations).map(kind => {
                        let kindRegistrations = WBSRegistrations.filter(r => r.typeId === kind)

                        return getTicketNumbers(kindRegistrations).map(ticket => {
                            let ticketRegistrations = kindRegistrations.filter(r => r.ticket === ticket)

                            if (ticketRegistrations.length > 0) {
                                let r = ticketRegistrations[0]
                                let shProject = showProject()
                                let shWBS = showWBSValue(r.wbsId)
                                return (
                                    <TableRow hover key={r.id}>
                                        <TableCell>{shProject ? project.name : ''}</TableCell>
                                        <TableCell>{shWBS ? `${r.wbsCode} - ${r.wbsName}` : ''}</TableCell>
                                        <TableCell>{r.ticket}</TableCell>
                                        <TableCell>{r.typeName}</TableCell>
                                        <HoursRow dates={getPeriodDays(registrations[0].date)}
                                            registrations={ticketRegistrations}
                                            onRegistrationClicked={onRegistrationClicked}
                                            onRegistrationBulkEditClicked={onRegistrationBulkEditClicked} />
                                        <TableCell align='right' sx={{ fontWeight: "bold" }}>{formatHours(getSumOfHours(ticketRegistrations))}</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold" }}>{shWBS ? formatHours(getSumOfHours(WBSRegistrations)) : ''}</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold" }}>{shProject ? formatHours(getSumOfHours(registrations)) : ''}</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold" }}>{shProject ? formatHours(totalHours) : ''}</TableCell>
                                    </TableRow>
                                )
                            }
                            else {
                                return (<div>This should not happen</div>)
                            }
                        })
                    })
                }
                )
                }
            </ >
        )
    } else {
        return <TableRow><TableCell> loading... </TableCell></TableRow>
    }
}