import * as Types from "./types"
import * as Constants from './constants'
import { RecurrenceInfo, RegistrationData } from "../../types"

export const loadDayDataViaSagaAction = (data: { date: Date }): Types.LoadDayDataViaSagaAction => {
    return {
        payload: data,
        type: Constants.LOAD_DAY_DATA_SAGA
    }
}

export const loadMonthRegistrationViaSagaAction = (data: { dateInPeriod: Date }): Types.LoadMonthRegistrationsViaSagaAction => {
    return {
        payload: data,
        type: Constants.LOAD_MONTH_REGISTRATIONS_SAGA
    }
}

export const saveDayDataViaSagaAction = (data: { registrations: RegistrationData[] }): Types.SaveDayDataViaSagaAction => {
    return {
        payload: data,
        type: Constants.SAVE_DAY_DATA_SAGA
    }
}

export const deleteRegistrationViaSagaAction = (data: { id: number }): Types.DeleteRegistrationViaSagaAction => {
    return {
        payload: data,
        type: Constants.DELETE_REGISTRATION_SAGA
    }
}

export const approveHoursViaSagaAction = (data: { registrationIds: number[] }): Types.ApproveHoursViaSagaAction => {
    return {
        payload: data,
        type: Constants.APPROVE_HOURS_SAGA
    }
}
export const saveUserRegistrationsViaSagaAction = (data: { registrations: RegistrationData[] }): Types.SaveUserRegistrationsViaSagaAction => {
    return {
        payload: data,
        type: Constants.SAVE_USER_REGISTRATIONS_SAGA
    }
}

export const loadUserMonthRegistrationsViaSagaAction = (data: { userId: number, dateInPeriod: Date }): Types.LoadUserMonthRegistrationsViaSagaAction => {
    return {
        payload: data,
        type: Constants.LOAD_USER_MONTH_REGISTRATIONS_SAGA
    }
}

export const deleteUserRegistrationViaSagaAction = (data: { id: number }): Types.DeleteUserRegistrationViaSagaAction => {
    return {
        payload: data,
        type: Constants.DELETE_USER_REGISTRATION_SAGA
    }
}

export const createRecurrentRegistrationViaSagaAction = (data: { registration: RegistrationData, recurrence: RecurrenceInfo }): Types.CreateRecurrentRegistrationViaSagaAction => {
    return {
        payload: data,
        type: Constants.CREATE_RECURRENT_REGISTRATION_SAGA
    }
}

export const createUserRecurrentRegistrationViaSagaAction = (data: { registration: RegistrationData, recurrence: RecurrenceInfo }): Types.CreateUserRecurrentRegistrationViaSagaAction => {
    return {
        payload: data,
        type: Constants.CREATE_USER_RECURRENT_REGISTRATION_SAGA
    }
}