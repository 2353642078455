import { Paper, Stack, Typography } from "@mui/material"
import { UserInfoDisplayProps } from "./types"
import { EditIcon } from "../../../../../../components/formElements/EditIcon"

export const UserInfoDisplay: React.FC<UserInfoDisplayProps> = ({ user, onEditIconClick }) => {
    return (
        <Paper variant="outlined" sx={{ width: '100%' }}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} padding={1}>
                <Stack direction={'column'} justifyContent={'flex-end'} spacing={1}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{user.displayName}</Typography>
                    <Typography variant="body2">{`User name (EID): ${user.userName}`}</Typography>
                </Stack>
                <Stack direction={'column'} justifyContent={'flex-end'} spacing={1}>
                    <Typography variant="body2">{`Email: ${user.email}`}</Typography>
                    <Typography variant="body2">{`Is active: ${user.isActive}`}</Typography>
                </Stack>
                <EditIcon size="large" onClick={onEditIconClick} />
            </Stack>
        </Paper>
    )
}