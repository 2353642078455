import { TextField, List, ListItemButton, ListItemText, Stack, ListItem, Box } from "@mui/material"
import { useState, MouseEvent } from "react"
import { ProjectData, WBSData } from "../../../../../state/types";
import { COMMON_LABELS_TRANSLATIONS, WBS_PROJECTEDITOR_TRANSLATIONS } from "../../../translations";
import { useAppTranslationByLocation } from "../../../../../translations";
import { TFunction } from "i18next";
import { ProjectSelectProps, WbsClickType } from "../types";
import { EditIcon } from "../../../../../components/formElements/EditIcon";

export const ProjectSelect: React.FC<ProjectSelectProps> = ({ projects, onProjectSelected, onWbsSelected }) => {
    const { t } = useAppTranslationByLocation();

    const [searchProject, setSearchProject] = useState('');


    const handleProjectsSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchProject(event.target.value);
    };

    const filteredProjectsByName = projects.filter((data) =>
        data.name.toLowerCase().includes(searchProject.toLowerCase()))

    const handleWbsEditClick = (event: MouseEvent, wbs: WBSData) => {
        event.stopPropagation();
        onWbsSelected(wbs, WbsClickType.EDIT)
    }


    return (
        <Stack style={{ flexGrow: 1, position: 'relative', width: '100%', height: '100%' }}>
            <TextField
                style={{ marginBottom: '5px' }}
                label={t(WBS_PROJECTEDITOR_TRANSLATIONS.SEARCH_PROJECTS_LABEL)}
                variant="outlined"
                fullWidth
                value={searchProject}
                onChange={handleProjectsSearch}
            />
            {/* This is hack to make the list fill available space and then overflow
             https://stackoverflow.com/questions/14262938/child-with-max-height-100-overflows-parent
             */}
            <Box style={{ position: 'absolute', overflow: 'auto', inset: "50px 0px 5px" }}>
                <List style={{ overflow: 'auto', paddingLeft: 10, paddingRight: 10, marginTop: 8, marginBottom: 8 }}>
                    {filteredProjectsByName.map((project: ProjectData) => (
                        <div key={project.id}>
                            <ListItem style={{ padding: 0 }}>
                                <ListItemText primary={project.name} secondary={getTranslatedStatusLabel(project.active, t)} style={{ margin: 4 }} />
                                <EditIcon onClick={() => onProjectSelected(project)} />
                            </ListItem>
                            <List style={{ padding: 0 }}>
                                {
                                    project.WBS
                                        .sort((wbs1, wbs2) => wbs1.id > wbs2.id ? 1 : -1)
                                        .map((wbs: WBSData) => (
                                            <ListItemButton key={wbs.id} onClick={() => onWbsSelected(wbs, WbsClickType.SHOW)} style={{ padding: 0 }}>
                                                <ListItemText primary={`${wbs.name} (${wbs.code})`} secondary={getTranslatedStatusLabel(wbs.active, t)}
                                                    style={{ margin: 0, marginLeft: 24 }} />
                                                <EditIcon onClick={(e) => handleWbsEditClick(e, wbs)} />
                                            </ListItemButton>
                                        ))
                                }
                            </List>
                        </div>
                    ))}
                </List>
            </Box>
        </Stack>
    )
}

const getTranslatedStatusLabel = (status: boolean, t: TFunction<"hat-web", undefined, "hat-web">) => {
    if (status) {
        return `Status: ${t(COMMON_LABELS_TRANSLATIONS.ACTIVE)}`
    }
    else {
        return `Status: ${t(COMMON_LABELS_TRANSLATIONS.INACTIVE)}`
    }
}