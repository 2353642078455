import { useEffect, useState } from "react";
import { RecurrenceInfo, RegistrationData } from "../../../../state/types";
import { PeriodOverview, FormModal, BulkChangeModal, LoadingModal } from '../index';
import { PeriodOverviewWithModalsProps } from "./types";

export const PeriodOverviewWithModals: React.FC<PeriodOverviewWithModalsProps> = (
    { selectedDate, userProjects, allProjects, kinds, registrations, newRegistration, isLoading,
        onFormModalSubmitted, onFormModalDelete, onFormModalClose, onBulkChangeModalSubmitted }) => {

    //registration selected for FormModal
    const [selectedRegistration, setSelectedRegistration] = useState<RegistrationData | null>(null);
    //registrations selected for BulkChangeModal
    const [selectedRegistrations, setSelectedRegistrations] = useState<RegistrationData[]>([]);

    const [formModalOpen, setFormModalOpen] = useState<boolean>(false);
    const [bulkChangeModalOpen, setBulkChangeModalOpen] = useState<boolean>(false);
    const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(isLoading);

    const handleFormModalOpen = () => setFormModalOpen(true);
    const handleFormModalClose = () => {
        setFormModalOpen(false);
        onFormModalClose();
    }

    const handleBulkChangeModalOpen = () => setBulkChangeModalOpen(true);
    const handleBulkChangeModalClose = () => setBulkChangeModalOpen(false);

    useEffect(() => {
        if (newRegistration) {
            setSelectedRegistration(null);
            handleFormModalOpen();
        };
    }, [newRegistration])

    useEffect(() => {
        setLoadingModalOpen(isLoading)
    }, [isLoading])

    const onRegistrationClicked = (registration: RegistrationData): void => {
        setSelectedRegistration(registration);
        handleFormModalOpen();
    };

    const onRegistrationBulkEditClicked = (registrations: RegistrationData[]): void => {
        setSelectedRegistrations(registrations);
        handleBulkChangeModalOpen();
    }

    const handleFormModalSubmitted = (registration: RegistrationData, recurrence?: RecurrenceInfo) => {
        onFormModalSubmitted(registration, recurrence);
        handleFormModalClose();
    }

    const handleFormModalDelete = (registration: RegistrationData) => {
        onFormModalDelete(registration);
        handleFormModalClose();
    }

    const handleBulkChangeModalSubmitted = (registrations: RegistrationData[]) => {
        onBulkChangeModalSubmitted(registrations)
        handleBulkChangeModalClose();
    }

    return (
        <>
            <PeriodOverview
                selectedDate={selectedDate}
                projects={allProjects}
                registrations={registrations}
                onRegistrationClicked={onRegistrationClicked}
                onRegistrationBulkEditClicked={onRegistrationBulkEditClicked}
            />
            <FormModal
                open={formModalOpen}
                onClose={handleFormModalClose}
                registration={selectedRegistration}
                kinds={kinds}
                projects={userProjects}
                defaultDate={selectedDate}
                onFormSubmitted={handleFormModalSubmitted}
                onDelete={handleFormModalDelete}
            />
            <BulkChangeModal
                open={bulkChangeModalOpen}
                onClose={handleBulkChangeModalClose}
                registrations={selectedRegistrations}
                kinds={kinds}
                projects={userProjects}
                onFormSubmitted={handleBulkChangeModalSubmitted}
            />
            <LoadingModal
                open={loadingModalOpen}
                onClose={() => setLoadingModalOpen(false)}
            />
        </>
    )
}