import { UserApi } from "../../../apis/sms.web.api/generatedCode"
import { UserData, UserSettings } from "../../state/types"
import { getAuthorizationHeader } from "../../utils"
import { ILoggerService } from "../types"
import { mapUser, mapToSettings } from "../utils"

export class UserService {
    _loggerService: ILoggerService
    _userApi: UserApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._userApi = new UserApi(undefined, basePath)
        this._token = token
    }

    // Returns UserData list without the WBS codes
    // Ideal for listing only the names of the users
    getUsers = async (): Promise<UserData[]> => {
        try {
            const users = await this._userApi.apiUserGetUsersGet(getAuthorizationHeader(this._token));
            return users.data.map(user => mapUser(user)).sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return [];
        }
    }

    getUserWithRoles = async (): Promise<string[]> => {
        try {
            const response = await this._userApi.apiUserGetUserWithRolesGet(getAuthorizationHeader(this._token));
            return response.data.roles!.map(r => r.name!)
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return [];
        }
    }

    getUserSettings = async (): Promise<UserSettings> => {
        try {
            const response = await this._userApi.apiUserGetUserSettingsGet(getAuthorizationHeader(this._token));
            return mapToSettings(response.data);
        }
        catch (ex) {
            this._loggerService.logError("A error happened when retrieving user settings");
            console.error(ex);
            return {} as UserSettings;
        }
    }

    updateUserSettings = async (settings: UserSettings) => {
        try {
            await this._userApi.apiUserUpdateUserSettingsPut(settings, getAuthorizationHeader(this._token))
        }
        catch (ex) {
            this._loggerService.logError("A error happened when updating user settings");
            console.error(ex);
        }
    }

    CreateUser = async (user: UserData) => {
        try {
            await this._userApi.apiUserCreatePost(user, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }

    UpdateUser = async (user: UserData) => {
        try {
            await this._userApi.apiUserUpdatePut(user, getAuthorizationHeader(this._token));
        }
        catch (ex) {
            this._loggerService.logError(ex);
        }
    }

    ValidateUser = async (): Promise<boolean> => {
        try {
            var result = await this._userApi.apiUserCheckIfUserExistsGet(getAuthorizationHeader(this._token));
            return result.status === 200;
        }
        catch (ex) {
            this._loggerService.logError(ex);
            return false;
        }
    }
}
