import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box, Toolbar, List, CssBaseline, AppBar, Link } from '@mui/material';
import { NavBar } from "../navBar";
import { SideBarMenuItemType } from "./components/sideBarMenuItem/types";
import { SideBarMenuItem } from "./components/sideBarMenuItem";
import { SideBarMenuProps } from "./types";
import { isAdmin, isProduction } from "../../utils";
import { Link as RouterLink } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const SideBarMenu: React.FC<SideBarMenuProps> = ({ role }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar sx={{ zIndex: theme.zIndex.drawer + 1 }}>
          <NavBar handleDrawer={handleDrawer} />
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar />
          <List>
            {!isProduction() &&
              <Link to="homeView" component={RouterLink}>
                <SideBarMenuItem open={open} itemType={SideBarMenuItemType.Home} />
              </Link>
            }
            <Link to="dayView" component={RouterLink}>
              <SideBarMenuItem open={open} itemType={SideBarMenuItemType.Day} />
            </Link>
            <Link to="periodView" component={RouterLink}>
              <SideBarMenuItem open={open} itemType={SideBarMenuItemType.Period} />
            </Link>
            {isAdmin(role) &&
              <Link to="adminView" component={RouterLink}>
                <SideBarMenuItem open={open} itemType={SideBarMenuItemType.Admin} />
              </Link>
            }
          </List>
        </Drawer>
      </Box>
    </>
  );
};
