import { Box, Stack, Button } from "@mui/material";
import { PeriodSelect } from '../../../../components';
import { FC, useEffect, useState } from "react";
import { UserSelect } from "./components/UserSelect";
import { RecurrenceInfo, RegistrationData, UserData } from "../../../../state/types";
import { useDispatch, useSelector } from "react-redux";
import {
    loadUserMonthRegistrationsViaSagaAction,
    loadUserProjectsViaSagaAction,
    loadKindDataViaSagaAction,
    loadAllUsersViaSagaAction,
    approveHoursViaSagaAction,
    deleteUserRegistrationViaSagaAction,
    saveUserRegistrationsViaSagaAction,
    loadAllProjectDataViaSagaAction,
    createUserRecurrentRegistrationViaSagaAction,
} from "../../../../state/sagas/actions";
import { HATState } from "../../../../state";
import { allApproved, downloadFile, formatDateFullTimestamp, periodFilterPredicate } from '../../../../utils'
import { useAppTranslationByLocation } from "../../../../translations";
import { APPROVE_HOURS_LABELS } from './translations'
import { Add, HowToReg, IosShareOutlined } from "@mui/icons-material";
import { PeriodOverviewWithModals } from '../../../periodView/components';
import { impersonationService } from "../../../../services";
import { userInfoTokenSelector } from "../../../../state/selectors";
import { registrationsLoadingFinishedAction } from "../../../../state/redux/actions";
import { allProjectsSelector, selectedUserMonthRegistrationsSelector, selectedUserProjectsSelector, allActiveUsersSelector } from "../../../../state/selectors/adminData";
import { kindsSelector } from "../../../../state/selectors/data";

export const ApproveHours: FC = () => {
    const [selectedUser, setSelectedUser] = useState<UserData | null>(null)
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [newRegistration, setNewRegistration] = useState<boolean>(false);
    const [registrationsLoading, setRegistrationsLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const allProjects = useSelector(allProjectsSelector)
    const kinds = useSelector(kindsSelector)
    const userProjects = useSelector(selectedUserProjectsSelector)
    const registrations = useSelector((state: HATState) => selectedUserMonthRegistrationsSelector(state, selectedDate))

    const users = useSelector(allActiveUsersSelector)
    const loadingFinished = useSelector((state: HATState) => state.notifications.registrationsLoadingFinished)
    const token: string = useSelector(userInfoTokenSelector);
    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        //reload data when selected date or user is changed
        if (selectedUser) {
            dispatch(loadUserMonthRegistrationsViaSagaAction({ userId: selectedUser?.id, dateInPeriod: selectedDate }));
            dispatch(loadUserProjectsViaSagaAction({ userId: selectedUser?.id }));
            setRegistrationsLoading(true);
        }
    }, [dispatch, selectedDate, selectedUser]);

    useEffect(() => {
        //load static form data at page load
        dispatch(loadKindDataViaSagaAction())
        dispatch(loadAllUsersViaSagaAction())
        dispatch(loadAllProjectDataViaSagaAction());
    }, [dispatch])

    useEffect(() => {
        if (loadingFinished) {
            setRegistrationsLoading(false)
            dispatch(registrationsLoadingFinishedAction(false))
        }
    }, [loadingFinished, dispatch])

    const onDateChanged = (selectedDate: Date) => {
        setSelectedDate(selectedDate);
    }

    const onUserChanged = (selectedUser: UserData) => {
        setSelectedUser(selectedUser);
    }

    const onApproveButtonClick = () => {
        dispatch(approveHoursViaSagaAction({ registrationIds: registrations.filter(r => periodFilterPredicate(r.date, selectedDate)).map(r => r.id) }))
    }

    const onFormModalSubmitted = (registration: RegistrationData, recurrence?: RecurrenceInfo): void => {
        //update the new registration with selected userId
        registration.userId = selectedUser!.id
        if (recurrence) {
            dispatch(createUserRecurrentRegistrationViaSagaAction({ registration, recurrence }))
        } else {
            dispatch(saveUserRegistrationsViaSagaAction({ registrations: [registration] }))
        }

        setNewRegistration(false)
        setRegistrationsLoading(true);
    }
    const onFormModalDelete = (registration: RegistrationData): void => {
        dispatch(deleteUserRegistrationViaSagaAction({ id: registration.id }))
        setRegistrationsLoading(true);
    }

    const onBulkChangeModalSubmitted = (registrations: RegistrationData[]): void => {
        dispatch(saveUserRegistrationsViaSagaAction({ registrations }))
        setRegistrationsLoading(true);
    }

    const onNewRegistrationClicked = () => setNewRegistration(true)
    const onFormModalClose = () => setNewRegistration(false)

    const onDownloadButtonClick = () => {
        impersonationService(token)
            .getXmlExportForPeriodAndUser(selectedDate, selectedUser!.id)
            .then((data) => downloadFile(data, `myte_export_${selectedUser?.userName}_${formatDateFullTimestamp(new Date())}.xml`, 'text/xml'));
    };

    const isApproveButtonActive = (): boolean => (selectedUser != null) && (registrations.filter(r => periodFilterPredicate(r.date, selectedDate)).length > 0)
    const isAddButtonActive = (): boolean => selectedUser != null

    return (
        <Stack spacing={1} padding={1} flexGrow={1}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction={'row'} spacing={2}>
                    <PeriodSelect selectedDate={selectedDate} onDateChanged={onDateChanged} />
                    <UserSelect users={users} handleUserSelectClick={onUserChanged} />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <Button variant="outlined"
                        disabled={!isAddButtonActive()}
                        onClick={onNewRegistrationClicked}
                        startIcon={<Add />}>{t(APPROVE_HOURS_LABELS.ADD_BUTTON)}
                    </Button>
                    <Button variant="outlined"
                        disabled={!isApproveButtonActive()}
                        onClick={onApproveButtonClick}
                        startIcon={<HowToReg />}>{t(APPROVE_HOURS_LABELS.APPROVE)}
                    </Button>
                    <Button variant="outlined"
                        disabled={!allApproved(registrations.filter(r => periodFilterPredicate(r.date, selectedDate)))}
                        onClick={onDownloadButtonClick}
                        startIcon={<IosShareOutlined />}>{t(APPROVE_HOURS_LABELS.EXPORT_MYTE_BUTTON)}
                    </Button>
                </Stack>
            </Box>
            <PeriodOverviewWithModals
                selectedDate={selectedDate}
                userProjects={userProjects}
                allProjects={allProjects}
                kinds={kinds}
                registrations={selectedUser != null ? registrations : []}
                newRegistration={newRegistration}
                isLoading={registrationsLoading}
                onFormModalSubmitted={onFormModalSubmitted}
                onFormModalDelete={onFormModalDelete}
                onFormModalClose={onFormModalClose}
                onBulkChangeModalSubmitted={onBulkChangeModalSubmitted}
            />
        </Stack>
    );
};