import { WbsDeleteResultAction, UserVerificationFinishedAction, RegistrationsLoadingFinishedAction, UsersForWbsSavedAction } from "./types";
import * as Constants from './constants'
import { ResponseStatus } from "../../../../services/types";

export const wbsDeleteResultAction = (result?: ResponseStatus): WbsDeleteResultAction => {
    return {
        type: Constants.WBS_DELETE_RESULT,
        payload: result
    }
}

export const userVerificationFinishedAction = (result: boolean): UserVerificationFinishedAction => {
    return {
        type: Constants.USER_VERIFICATION_FINISHED,
        payload: result
    }
}

export const registrationsLoadingFinishedAction = (result: boolean): RegistrationsLoadingFinishedAction => {
    return {
        type: Constants.REGISTRATIONS_LOADING_FINISHED,
        payload: result
    }
}

export const usersForWbsSavedAction = (result?: ResponseStatus): UsersForWbsSavedAction => {
    return {
        type: Constants.USERS_FOR_WBS_SAVED,
        payload: result
    }
}