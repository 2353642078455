//Types of SideBarMenu items
export enum SideBarMenuItemType {
    Home,
    Day,
    Period,
    Admin
}

export type SideBarMenuItemProps = {
    open: boolean,
    itemType: SideBarMenuItemType
}