/* tslint:disable */
/* eslint-disable */
/**
 * HAT 2.0 API
 * An ASP.NET 6.0 Web API for managing hours worked on different projects
 *
 * The version of the OpenAPI document: v1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface FilterDto
 */
export interface FilterDto {
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterDto
     */
    'projectsId'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterDto
     */
    'wbsId'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterDto
     */
    'kindId'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface HourDto
 */
export interface HourDto {
    /**
     * 
     * @type {number}
     * @memberof HourDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourDto
     */
    'chargeDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof HourDto
     */
    'kindId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourDto
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HourDto
     */
    'wbsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourDto
     */
    'activity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HourDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof HourDto
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface HourViewDto
 */
export interface HourViewDto {
    /**
     * 
     * @type {number}
     * @memberof HourViewDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'chargeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'activity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HourViewDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof HourViewDto
     */
    'wbsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'wbsName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'wbsCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HourViewDto
     */
    'isApproved'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HourViewDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof HourViewDto
     */
    'kindId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'kindName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HourViewDto
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourViewDto
     */
    'projectName'?: string | null;
}
/**
 * 
 * @export
 * @interface KindDto
 */
export interface KindDto {
    /**
     * 
     * @type {number}
     * @memberof KindDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof KindDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KindDto
     */
    'reportOrder'?: number;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<WBSDto>}
     * @memberof ProjectDto
     */
    'wbs'?: Array<WBSDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface RecurrenceDto
 */
export interface RecurrenceDto {
    /**
     * 
     * @type {number}
     * @memberof RecurrenceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceDto
     */
    'chargeDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceDto
     */
    'kindId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceDto
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceDto
     */
    'wbsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceDto
     */
    'activity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceDto
     */
    'userId'?: number;
    /**
     * 
     * @type {RecurrenceType}
     * @memberof RecurrenceDto
     */
    'type'?: RecurrenceType;
    /**
     * 
     * @type {RecurrencePattern}
     * @memberof RecurrenceDto
     */
    'pattern'?: RecurrencePattern;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceDto
     */
    'count'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RecurrencePattern = {
    Daily: 'Daily',
    Weekly: 'Weekly'
} as const;

export type RecurrencePattern = typeof RecurrencePattern[keyof typeof RecurrencePattern];


/**
 * 
 * @export
 * @enum {string}
 */

export const RecurrenceType = {
    Date: 'Date',
    Count: 'Count'
} as const;

export type RecurrenceType = typeof RecurrenceType[keyof typeof RecurrenceType];


/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof UserDto
     */
    'roles'?: Array<RoleDto> | null;
}
/**
 * 
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDto
     */
    'darkMode'?: boolean;
}
/**
 * 
 * @export
 * @interface WBSDto
 */
export interface WBSDto {
    /**
     * 
     * @type {number}
     * @memberof WBSDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WBSDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WBSDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WBSDto
     */
    'projectId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WBSDto
     */
    'isActive'?: boolean;
}

/**
 * HourApi - axios parameter creator
 * @export
 */
export const HourApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourApproveHoursPost: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Hour/ApproveHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update provided records.
         * @param {Array<HourDto>} [hourDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourCreateOrUpdateHoursPost: async (hourDto?: Array<HourDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Hour/CreateOrUpdateHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hourDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
         * @param {RecurrenceDto} [recurrenceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourCreateRecurrentHoursPost: async (recurrenceDto?: RecurrenceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Hour/CreateRecurrentHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recurrenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [hoursRegistrationIdToBeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourDeleteDelete: async (hoursRegistrationIdToBeDeleted?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Hour/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hoursRegistrationIdToBeDeleted !== undefined) {
                localVarQueryParameter['hoursRegistrationIdToBeDeleted'] = hoursRegistrationIdToBeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get myTE xml import file with charge records for given period.
         * @param {string} [dateInPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourGenerateMyTEXMLExportGet: async (dateInPeriod?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Hour/GenerateMyTEXMLExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateInPeriod !== undefined) {
                localVarQueryParameter['dateInPeriod'] = (dateInPeriod as any instanceof Date) ?
                    (dateInPeriod as any).toISOString() :
                    dateInPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourGetHoursBetweenDatesGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Hour/GetHoursBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HourApi - functional programming interface
 * @export
 */
export const HourApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HourApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHourApproveHoursPost(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHourApproveHoursPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or update provided records.
         * @param {Array<HourDto>} [hourDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHourCreateOrUpdateHoursPost(hourDto?: Array<HourDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHourCreateOrUpdateHoursPost(hourDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
         * @param {RecurrenceDto} [recurrenceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHourCreateRecurrentHoursPost(recurrenceDto?: RecurrenceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHourCreateRecurrentHoursPost(recurrenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [hoursRegistrationIdToBeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHourDeleteDelete(hoursRegistrationIdToBeDeleted?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHourDeleteDelete(hoursRegistrationIdToBeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get myTE xml import file with charge records for given period.
         * @param {string} [dateInPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHourGenerateMyTEXMLExportGet(dateInPeriod?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHourGenerateMyTEXMLExportGet(dateInPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHourGetHoursBetweenDatesGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHourGetHoursBetweenDatesGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HourApi - factory interface
 * @export
 */
export const HourApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HourApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourApproveHoursPost(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiHourApproveHoursPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update provided records.
         * @param {Array<HourDto>} [hourDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourCreateOrUpdateHoursPost(hourDto?: Array<HourDto>, options?: any): AxiosPromise<Array<HourViewDto>> {
            return localVarFp.apiHourCreateOrUpdateHoursPost(hourDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
         * @param {RecurrenceDto} [recurrenceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourCreateRecurrentHoursPost(recurrenceDto?: RecurrenceDto, options?: any): AxiosPromise<Array<HourViewDto>> {
            return localVarFp.apiHourCreateRecurrentHoursPost(recurrenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [hoursRegistrationIdToBeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourDeleteDelete(hoursRegistrationIdToBeDeleted?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiHourDeleteDelete(hoursRegistrationIdToBeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get myTE xml import file with charge records for given period.
         * @param {string} [dateInPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourGenerateMyTEXMLExportGet(dateInPeriod?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiHourGenerateMyTEXMLExportGet(dateInPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHourGetHoursBetweenDatesGet(from?: string, to?: string, options?: any): AxiosPromise<Array<HourViewDto>> {
            return localVarFp.apiHourGetHoursBetweenDatesGet(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HourApi - object-oriented interface
 * @export
 * @class HourApi
 * @extends {BaseAPI}
 */
export class HourApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourApi
     */
    public apiHourApproveHoursPost(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return HourApiFp(this.configuration).apiHourApproveHoursPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update provided records.
     * @param {Array<HourDto>} [hourDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourApi
     */
    public apiHourCreateOrUpdateHoursPost(hourDto?: Array<HourDto>, options?: AxiosRequestConfig) {
        return HourApiFp(this.configuration).apiHourCreateOrUpdateHoursPost(hourDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
     * @param {RecurrenceDto} [recurrenceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourApi
     */
    public apiHourCreateRecurrentHoursPost(recurrenceDto?: RecurrenceDto, options?: AxiosRequestConfig) {
        return HourApiFp(this.configuration).apiHourCreateRecurrentHoursPost(recurrenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [hoursRegistrationIdToBeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourApi
     */
    public apiHourDeleteDelete(hoursRegistrationIdToBeDeleted?: number, options?: AxiosRequestConfig) {
        return HourApiFp(this.configuration).apiHourDeleteDelete(hoursRegistrationIdToBeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get myTE xml import file with charge records for given period.
     * @param {string} [dateInPeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourApi
     */
    public apiHourGenerateMyTEXMLExportGet(dateInPeriod?: string, options?: AxiosRequestConfig) {
        return HourApiFp(this.configuration).apiHourGenerateMyTEXMLExportGet(dateInPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourApi
     */
    public apiHourGetHoursBetweenDatesGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return HourApiFp(this.configuration).apiHourGetHoursBetweenDatesGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImpersonationApi - axios parameter creator
 * @export
 */
export const ImpersonationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create or update provided hours.
         * @param {Array<HourDto>} [hourDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationCreateOrUpdateHoursPost: async (hourDto?: Array<HourDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Impersonation/CreateOrUpdateHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hourDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
         * @param {RecurrenceDto} [recurrenceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationCreateRecurrentHoursPost: async (recurrenceDto?: RecurrenceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Impersonation/CreateRecurrentHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recurrenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationDeleteDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Impersonation/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateInPeriod] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationGenerateMyTeXmlExportGet: async (dateInPeriod?: string, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Impersonation/GenerateMyTeXmlExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateInPeriod !== undefined) {
                localVarQueryParameter['dateInPeriod'] = (dateInPeriod as any instanceof Date) ?
                    (dateInPeriod as any).toISOString() :
                    dateInPeriod;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationGetHoursOfUserBetweenDatesGet: async (userId?: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Impersonation/GetHoursOfUserBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImpersonationApi - functional programming interface
 * @export
 */
export const ImpersonationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImpersonationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create or update provided hours.
         * @param {Array<HourDto>} [hourDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImpersonationCreateOrUpdateHoursPost(hourDto?: Array<HourDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImpersonationCreateOrUpdateHoursPost(hourDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
         * @param {RecurrenceDto} [recurrenceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImpersonationCreateRecurrentHoursPost(recurrenceDto?: RecurrenceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImpersonationCreateRecurrentHoursPost(recurrenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImpersonationDeleteDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImpersonationDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateInPeriod] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImpersonationGenerateMyTeXmlExportGet(dateInPeriod?: string, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImpersonationGenerateMyTeXmlExportGet(dateInPeriod, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImpersonationGetHoursOfUserBetweenDatesGet(userId?: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImpersonationGetHoursOfUserBetweenDatesGet(userId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImpersonationApi - factory interface
 * @export
 */
export const ImpersonationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImpersonationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create or update provided hours.
         * @param {Array<HourDto>} [hourDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationCreateOrUpdateHoursPost(hourDto?: Array<HourDto>, options?: any): AxiosPromise<Array<HourViewDto>> {
            return localVarFp.apiImpersonationCreateOrUpdateHoursPost(hourDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
         * @param {RecurrenceDto} [recurrenceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationCreateRecurrentHoursPost(recurrenceDto?: RecurrenceDto, options?: any): AxiosPromise<Array<HourViewDto>> {
            return localVarFp.apiImpersonationCreateRecurrentHoursPost(recurrenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationDeleteDelete(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiImpersonationDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateInPeriod] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationGenerateMyTeXmlExportGet(dateInPeriod?: string, userId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiImpersonationGenerateMyTeXmlExportGet(dateInPeriod, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImpersonationGetHoursOfUserBetweenDatesGet(userId?: number, from?: string, to?: string, options?: any): AxiosPromise<Array<HourViewDto>> {
            return localVarFp.apiImpersonationGetHoursOfUserBetweenDatesGet(userId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImpersonationApi - object-oriented interface
 * @export
 * @class ImpersonationApi
 * @extends {BaseAPI}
 */
export class ImpersonationApi extends BaseAPI {
    /**
     * 
     * @summary Create or update provided hours.
     * @param {Array<HourDto>} [hourDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonationApi
     */
    public apiImpersonationCreateOrUpdateHoursPost(hourDto?: Array<HourDto>, options?: AxiosRequestConfig) {
        return ImpersonationApiFp(this.configuration).apiImpersonationCreateOrUpdateHoursPost(hourDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple recurrent records with same WBS, type, ticket number, hours and description
     * @param {RecurrenceDto} [recurrenceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonationApi
     */
    public apiImpersonationCreateRecurrentHoursPost(recurrenceDto?: RecurrenceDto, options?: AxiosRequestConfig) {
        return ImpersonationApiFp(this.configuration).apiImpersonationCreateRecurrentHoursPost(recurrenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonationApi
     */
    public apiImpersonationDeleteDelete(id?: number, options?: AxiosRequestConfig) {
        return ImpersonationApiFp(this.configuration).apiImpersonationDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateInPeriod] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonationApi
     */
    public apiImpersonationGenerateMyTeXmlExportGet(dateInPeriod?: string, userId?: number, options?: AxiosRequestConfig) {
        return ImpersonationApiFp(this.configuration).apiImpersonationGenerateMyTeXmlExportGet(dateInPeriod, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonationApi
     */
    public apiImpersonationGetHoursOfUserBetweenDatesGet(userId?: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return ImpersonationApiFp(this.configuration).apiImpersonationGetHoursOfUserBetweenDatesGet(userId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KindApi - axios parameter creator
 * @export
 */
export const KindApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KindDto} [kindDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindCreateKindPost: async (kindDto?: KindDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kind/CreateKind`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindGetAllKindsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kind/GetAllKinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindGetKindByIdIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKindGetKindByIdIdGet', 'id', id)
            const localVarPath = `/api/Kind/GetKindById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {KindDto} [kindDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindUpdateKindIdPut: async (id: string, kindDto?: KindDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKindUpdateKindIdPut', 'id', id)
            const localVarPath = `/api/Kind/UpdateKind/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KindApi - functional programming interface
 * @export
 */
export const KindApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KindApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KindDto} [kindDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKindCreateKindPost(kindDto?: KindDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKindCreateKindPost(kindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKindGetAllKindsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KindDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKindGetAllKindsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKindGetKindByIdIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KindDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKindGetKindByIdIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {KindDto} [kindDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKindUpdateKindIdPut(id: string, kindDto?: KindDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKindUpdateKindIdPut(id, kindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KindApi - factory interface
 * @export
 */
export const KindApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KindApiFp(configuration)
    return {
        /**
         * 
         * @param {KindDto} [kindDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindCreateKindPost(kindDto?: KindDto, options?: any): AxiosPromise<KindDto> {
            return localVarFp.apiKindCreateKindPost(kindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindGetAllKindsGet(options?: any): AxiosPromise<Array<KindDto>> {
            return localVarFp.apiKindGetAllKindsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindGetKindByIdIdGet(id: number, options?: any): AxiosPromise<KindDto> {
            return localVarFp.apiKindGetKindByIdIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {KindDto} [kindDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKindUpdateKindIdPut(id: string, kindDto?: KindDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiKindUpdateKindIdPut(id, kindDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KindApi - object-oriented interface
 * @export
 * @class KindApi
 * @extends {BaseAPI}
 */
export class KindApi extends BaseAPI {
    /**
     * 
     * @param {KindDto} [kindDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KindApi
     */
    public apiKindCreateKindPost(kindDto?: KindDto, options?: AxiosRequestConfig) {
        return KindApiFp(this.configuration).apiKindCreateKindPost(kindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KindApi
     */
    public apiKindGetAllKindsGet(options?: AxiosRequestConfig) {
        return KindApiFp(this.configuration).apiKindGetAllKindsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KindApi
     */
    public apiKindGetKindByIdIdGet(id: number, options?: AxiosRequestConfig) {
        return KindApiFp(this.configuration).apiKindGetKindByIdIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {KindDto} [kindDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KindApi
     */
    public apiKindUpdateKindIdPut(id: string, kindDto?: KindDto, options?: AxiosRequestConfig) {
        return KindApiFp(this.configuration).apiKindUpdateKindIdPut(id, kindDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectCreatePost: async (projectDto?: ProjectDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of all exsting projects. With their WBS info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetAllProjectsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetAllProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetOptionsOfCurrentUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetOptionsOfCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user and the WBS codes assigned to them
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetOptionsOfUserGet: async (userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetOptionsOfUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdatePut: async (projectDto?: ProjectDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectCreatePost(projectDto?: ProjectDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectCreatePost(projectDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of all exsting projects. With their WBS info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetAllProjectsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetAllProjectsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetOptionsOfCurrentUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetOptionsOfCurrentUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user and the WBS codes assigned to them
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetOptionsOfUserGet(userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetOptionsOfUserGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectUpdatePut(projectDto?: ProjectDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectUpdatePut(projectDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectCreatePost(projectDto?: ProjectDto, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.apiProjectCreatePost(projectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of all exsting projects. With their WBS info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetAllProjectsGet(options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.apiProjectGetAllProjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetOptionsOfCurrentUserGet(options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.apiProjectGetOptionsOfCurrentUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user and the WBS codes assigned to them
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetOptionsOfUserGet(userId?: number, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.apiProjectGetOptionsOfUserGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdatePut(projectDto?: ProjectDto, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.apiProjectUpdatePut(projectDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @param {ProjectDto} [projectDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectCreatePost(projectDto?: ProjectDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiProjectCreatePost(projectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of all exsting projects. With their WBS info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetAllProjectsGet(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiProjectGetAllProjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetOptionsOfCurrentUserGet(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiProjectGetOptionsOfCurrentUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user and the WBS codes assigned to them
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetOptionsOfUserGet(userId?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiProjectGetOptionsOfUserGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectDto} [projectDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectUpdatePut(projectDto?: ProjectDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiProjectUpdatePut(projectDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FilterDto} [filterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetDetailReportPost: async (filterDto?: FilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Report/GetDetailReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterDto} [filterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetTimeReportPost: async (filterDto?: FilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Report/GetTimeReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FilterDto} [filterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGetDetailReportPost(filterDto?: FilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGetDetailReportPost(filterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilterDto} [filterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportGetTimeReportPost(filterDto?: FilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportGetTimeReportPost(filterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {FilterDto} [filterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetDetailReportPost(filterDto?: FilterDto, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGetDetailReportPost(filterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilterDto} [filterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportGetTimeReportPost(filterDto?: FilterDto, options?: any): AxiosPromise<any> {
            return localVarFp.apiReportGetTimeReportPost(filterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {FilterDto} [filterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGetDetailReportPost(filterDto?: FilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportGetDetailReportPost(filterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilterDto} [filterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportGetTimeReportPost(filterDto?: FilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportGetTimeReportPost(filterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRoleIdDelete', 'id', id)
            const localVarPath = `/api/Role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRoleIdGet', 'id', id)
            const localVarPath = `/api/Role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdPut: async (id: string, roleDto?: RoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRoleIdPut', 'id', id)
            const localVarPath = `/api/Role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolePost: async (roleDto?: RoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleIdPut(id: string, roleDto?: RoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleIdPut(id, roleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolePost(roleDto?: RoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolePost(roleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiRoleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoleIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoleIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdPut(id: string, roleDto?: RoleDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoleIdPut(id, roleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolePost(roleDto?: RoleDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolePost(roleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleGet(options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleIdDelete(id: number, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleIdGet(id: number, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RoleDto} [roleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleIdPut(id: string, roleDto?: RoleDto, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleIdPut(id, roleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleDto} [roleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRolePost(roleDto?: RoleDto, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRolePost(roleDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCheckIfUserExistsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CheckIfUserExists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreatePost: async (userDto?: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user settings for application appearance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Id, Name and Roles of the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserWithRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserWithRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdatePut: async (userDto?: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user settings for application appearance.
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateUserSettingsPut: async (userSettingsDto?: UserSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUserSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCheckIfUserExistsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCheckIfUserExistsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreatePost(userDto?: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreatePost(userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user settings for application appearance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Id, Name and Roles of the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserWithRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserWithRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdatePut(userDto?: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdatePut(userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user settings for application appearance.
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateUserSettingsPut(userSettingsDto?: UserSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateUserSettingsPut(userSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCheckIfUserExistsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserCheckIfUserExistsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreatePost(userDto?: UserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserCreatePost(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user settings for application appearance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserSettingsGet(options?: any): AxiosPromise<UserSettingsDto> {
            return localVarFp.apiUserGetUserSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Id, Name and Roles of the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserWithRolesGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserGetUserWithRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersGet(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiUserGetUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdatePut(userDto?: UserDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUpdatePut(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user settings for application appearance.
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateUserSettingsPut(userSettingsDto?: UserSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUpdateUserSettingsPut(userSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCheckIfUserExistsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCheckIfUserExistsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreatePost(userDto?: UserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreatePost(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user settings for application appearance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserSettingsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUserSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Id, Name and Roles of the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserWithRolesGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUserWithRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUsersGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdatePut(userDto?: UserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdatePut(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user settings for application appearance.
     * @param {UserSettingsDto} [userSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateUserSettingsPut(userSettingsDto?: UserSettingsDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdateUserSettingsPut(userSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WBSApi - axios parameter creator
 * @export
 */
export const WBSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WBSDto} [wBSDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSCreatePost: async (wBSDto?: WBSDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wBSDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSDeleteDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [wbsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSGetUsersForWbsGet: async (wbsId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/GetUsersForWbs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wbsId !== undefined) {
                localVarQueryParameter['wbsId'] = wbsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSGetWbsForUserGet: async (userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/GetWbsForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [wbsId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSSaveUsersForWbsPost: async (wbsId?: number, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/SaveUsersForWbs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wbsId !== undefined) {
                localVarQueryParameter['wbsId'] = wbsId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSSaveWbsForUserPost: async (userId?: number, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/SaveWbsForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WBSDto} [wBSDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSUpdatePut: async (wBSDto?: WBSDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WBS/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wBSDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WBSApi - functional programming interface
 * @export
 */
export const WBSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WBSApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WBSDto} [wBSDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSCreatePost(wBSDto?: WBSDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WBSDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSCreatePost(wBSDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSDeleteDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [wbsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSGetUsersForWbsGet(wbsId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSGetUsersForWbsGet(wbsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSGetWbsForUserGet(userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSGetWbsForUserGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [wbsId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSSaveUsersForWbsPost(wbsId?: number, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSSaveUsersForWbsPost(wbsId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSSaveWbsForUserPost(userId?: number, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSSaveWbsForUserPost(userId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WBSDto} [wBSDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWBSUpdatePut(wBSDto?: WBSDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WBSDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWBSUpdatePut(wBSDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WBSApi - factory interface
 * @export
 */
export const WBSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WBSApiFp(configuration)
    return {
        /**
         * 
         * @param {WBSDto} [wBSDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSCreatePost(wBSDto?: WBSDto, options?: any): AxiosPromise<WBSDto> {
            return localVarFp.apiWBSCreatePost(wBSDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSDeleteDelete(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiWBSDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [wbsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSGetUsersForWbsGet(wbsId?: number, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiWBSGetUsersForWbsGet(wbsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSGetWbsForUserGet(userId?: number, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiWBSGetWbsForUserGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [wbsId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSSaveUsersForWbsPost(wbsId?: number, requestBody?: Array<number>, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiWBSSaveUsersForWbsPost(wbsId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSSaveWbsForUserPost(userId?: number, requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiWBSSaveWbsForUserPost(userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WBSDto} [wBSDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWBSUpdatePut(wBSDto?: WBSDto, options?: any): AxiosPromise<WBSDto> {
            return localVarFp.apiWBSUpdatePut(wBSDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WBSApi - object-oriented interface
 * @export
 * @class WBSApi
 * @extends {BaseAPI}
 */
export class WBSApi extends BaseAPI {
    /**
     * 
     * @param {WBSDto} [wBSDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSCreatePost(wBSDto?: WBSDto, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSCreatePost(wBSDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSDeleteDelete(id?: number, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [wbsId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSGetUsersForWbsGet(wbsId?: number, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSGetUsersForWbsGet(wbsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSGetWbsForUserGet(userId?: number, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSGetWbsForUserGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [wbsId] 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSSaveUsersForWbsPost(wbsId?: number, requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSSaveUsersForWbsPost(wbsId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSSaveWbsForUserPost(userId?: number, requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSSaveWbsForUserPost(userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WBSDto} [wBSDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WBSApi
     */
    public apiWBSUpdatePut(wBSDto?: WBSDto, options?: AxiosRequestConfig) {
        return WBSApiFp(this.configuration).apiWBSUpdatePut(wBSDto, options).then((request) => request(this.axios, this.basePath));
    }
}


