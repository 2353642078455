import { FC } from 'react'
import Button from '@mui/material/Button'
import { AD_LOGOUT_BUTTON_LABELS } from './translations'
import { useAppTranslationByLocation } from '../../translations'
import { useMsal } from '@azure/msal-react'

export type ADLogoutButtonProps = {
  labels?: Partial<typeof AD_LOGOUT_BUTTON_LABELS>
}

export const ADLogoutButton: FC<ADLogoutButtonProps> = ({
  labels = AD_LOGOUT_BUTTON_LABELS,
}) => {
  const ownLabels = { ...AD_LOGOUT_BUTTON_LABELS, ...labels }
  const { t } = useAppTranslationByLocation()
  const { instance } = useMsal()

  const handleClick =
    (logoutType: 'sso' | 'popup' | 'redirect') =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log({ event })
        if (logoutType === 'popup') {
          instance.logoutPopup({
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/',
          })
        } else if (logoutType === 'redirect') {
          instance.logoutRedirect({
            postLogoutRedirectUri: '/',
          })
        }
      }

  return (
    <div>
      <Button id="logout-button" onClick={handleClick('redirect')} color="secondary">
        {t(ownLabels.LOGOUT)}
      </Button>
    </div>
  )
}
