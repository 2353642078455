import { Box, Button, Modal, Stack, Switch, FormControlLabel } from "@mui/material"
import { modalStyle } from "../../../../../../components/styling"
import { UserEditModalProps } from "./types"
import { FormEventHandler, useEffect, useState } from "react"
import { FormTextField } from "../../../../../../components/formElements"
import { USER_EDIT_MODAL_TRANSLATIONS } from "./translations"
import { useAppTranslationByLocation } from "../../../../../../translations"


export const UserEditModal: React.FC<UserEditModalProps> = ({ open, onClose, user, onUserFormSubmitted }) => {
    const [displayName, setDisplayName] = useState<string>('')
    const [userName, setUserName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [isActive, setIsActive] = useState<boolean>(true)

    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        if (user) {
            setDisplayName(user.displayName)
            setUserName(user.userName)
            setEmail(user.email)
            setIsActive(user.isActive)
        } else {
            emptyForm()
        }
    }, [user])

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        onUserFormSubmitted({
            id: user ? user.id : 0,
            displayName: displayName,
            userName: userName,
            email: email,
            isActive: isActive,
        })
        emptyForm()
    }

    const emptyForm = () => {
        setDisplayName('')
        setUserName('')
        setEmail('')
        setIsActive(true)
    }

    function handleDisplayNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setDisplayName(event.target.value);
    }

    function handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setUserName(event.target.value);
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setEmail(event.target.value);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ mt: 1 }}>
                        <h2>{user ? t(USER_EDIT_MODAL_TRANSLATIONS.UPDATE_USER) : t(USER_EDIT_MODAL_TRANSLATIONS.CREATE_USER)}</h2>
                        <FormTextField fullWidth
                            id='input-display-name'
                            label={t(USER_EDIT_MODAL_TRANSLATIONS.DISPLAY_NAME)}
                            value={displayName}
                            onChange={handleDisplayNameChange} />
                        <FormTextField fullWidth
                            id='input-user-number'
                            label={t(USER_EDIT_MODAL_TRANSLATIONS.USER_NAME)}
                            value={userName}
                            onChange={handleUserNameChange} />
                        <FormTextField fullWidth
                            id='input-email'
                            label={t(USER_EDIT_MODAL_TRANSLATIONS.EMAIL)}
                            value={email}
                            onChange={handleEmailChange} />
                        <FormControlLabel
                            sx={{ alignSelf: "flex-start" }} style={{ marginLeft: "4px" }}
                            value="isactive"
                            control={<Switch checked={isActive} onClick={() => setIsActive(!isActive)} />}
                            label={t(USER_EDIT_MODAL_TRANSLATIONS.IS_ACTIVE) + ": "}
                            labelPlacement="start"
                        />
                        <Button
                            type="submit"
                            variant="contained">
                            {user ? t(USER_EDIT_MODAL_TRANSLATIONS.UPDATE) : t(USER_EDIT_MODAL_TRANSLATIONS.CREATE)}
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    )
}