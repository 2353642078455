import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox } from "@mui/material"
import { RegistrationsListingProps } from "./types"
import { formatDate, formatHours } from "../../../../utils"
import { useEffect, useState } from "react"
import { useAppTranslationByLocation } from "../../../../translations";
import { REGISTRATIONS_LISTING_LABELS } from "./translations";

export const RegistrationsListing: React.FC<RegistrationsListingProps> = ({ registrations, onChange }) => {
    const [checkboxState, setCheckboxState] = useState<boolean[]>(() => registrations.map(r => false))
    const [allChecked, setAllChecked] = useState<boolean>(false)

    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        onChange(registrations.filter((r, i) => checkboxState[i]))
    }, [onChange, registrations, checkboxState])

    const handleCheckboxChange = (index: number, checked: boolean) => {
        setCheckboxState(checkboxState.map((c, i) => (i === index) ? checked : c))
        if (!checked) setAllChecked(false)
    };

    const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked)
        setCheckboxState(checkboxState.map(r => event.target.checked))
    }

    return (
        <TableContainer component={Paper} sx={{ maxHeight: '250px' }}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{t(REGISTRATIONS_LISTING_LABELS.DATE)}</TableCell>
                        <TableCell>{t(REGISTRATIONS_LISTING_LABELS.ACTIVITY)}</TableCell>
                        <TableCell>{t(REGISTRATIONS_LISTING_LABELS.HOURS)}</TableCell>
                        <TableCell><Checkbox checked={allChecked} onChange={handleCheckAllChange} /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {registrations.map((r, i) => (
                        <TableRow key={r.id}>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatDate(r.date)}</TableCell>
                            <TableCell>{r.activity}</TableCell>
                            <TableCell align='right'>{formatHours(r.hours)}</TableCell>
                            <TableCell>
                                <Checkbox id={r.id.toString()} checked={checkboxState[i]} onChange={(e) => handleCheckboxChange(i, e.target.checked)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}