import { KindApi } from "../../../apis/sms.web.api/generatedCode"
import { KindData } from "../../state/types"
import { getAuthorizationHeader } from "../../utils"
import { ILoggerService } from "../types"

export class KindService {
    _loggerService: ILoggerService
    _kindApi: KindApi
    _token: string

    constructor(loggerService: ILoggerService, token: string, basePath: string) {
        this._loggerService = loggerService
        this._kindApi = new KindApi(undefined, basePath)
        this._token = token
    }

    getKinds = async (): Promise<KindData[]> => {
        try {
            const response = await this._kindApi.apiKindGetAllKindsGet(getAuthorizationHeader(this._token))
            return response.data.map(item => ({ id: item.id!, name: item.name! })).sort((a, b) => a.name.localeCompare(b.name));
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return []
        }
    }

    getKindById = async (kindId: number): Promise<KindData | undefined> => {
        try {
            const response = await this._kindApi.apiKindGetKindByIdIdGet(kindId, getAuthorizationHeader(this._token))
            return {
                id: response.data.id!,
                name: response.data.name!
            }
        }
        catch (ex) {
            this._loggerService.logError(ex)
            return undefined
        }
    }

    createKind = async (newKind: string) => {
        try {
            const kindToCreate = {
                id: -1,
                name: newKind
            }

            return await this._kindApi.apiKindCreateKindPost(kindToCreate, getAuthorizationHeader(this._token))
        }
        catch (ex) {
            this._loggerService.logError(ex)
        }
    }

    updateKind = async (kindIdToUpdate: number, newKindValue: string) => {
        try {
            const kindToUpdate = {
                id: kindIdToUpdate,
                name: newKindValue
            }

            return await this._kindApi.apiKindUpdateKindIdPut(kindIdToUpdate.toString(), kindToUpdate, getAuthorizationHeader(this._token))
        }
        catch (ex) {
            this._loggerService.logError(ex)
        }
    }
}
