export * from './loggerService/types'

/**
 * Used as a return type from service calls where no response is to be expected.
 *  
 * Signals if the action was completed OK or if there was some warning/error.
 */
export enum ResponseStatus {
    OK,
    WARNING,
    ERROR
}