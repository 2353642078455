import * as React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { ApproveHours, WBSProjectEditorView, UserEditorView, Reports } from './components';
import { FC } from 'react';
import { useAppTranslationByLocation } from '../../translations';
import { TAB_TITLES_TRANSLATIONS } from './translations';
import { TabPanelProps } from './types';

export const AdminView = () => {
    const [value, setValue] = React.useState(0);
    const { t } = useAppTranslationByLocation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Admin tabs">
                    <Tab label={t(TAB_TITLES_TRANSLATIONS.EDIT_WBS_AND_PROJECT)} />
                    <Tab label={t(TAB_TITLES_TRANSLATIONS.EDIT_USER)} />
                    <Tab label={t(TAB_TITLES_TRANSLATIONS.APPROVE_HOURS)} />
                    <Tab label={t(TAB_TITLES_TRANSLATIONS.REPORTS)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <WBSProjectEditorView />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UserEditorView />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ApproveHours />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Reports />
            </TabPanel>
        </Box>
    );
}

const TabPanel: FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ display: value !== index ? "none" : "flex", height: "100%" }}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, width: "100%", display: "flex" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}