import { Select, MenuItem, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import { FormSelectData } from './types';

type FormSelectInputProps = {
    id: string,
    label: string,
    value: any,
    data: FormSelectData[] | undefined,
    onChange: (event: SelectChangeEvent) => void,
    fullWidth?: boolean,
    required?: boolean
}

export const FormSelect: React.FC<FormSelectInputProps> = ({ id, label, value, data, onChange, fullWidth = true, required = true }) => {
    return (
        <FormControl fullWidth={fullWidth} required={required}>
            <InputLabel id={id + "_label"}>{label}</InputLabel>

            <Select label={label}
                labelId={id + "_label"}
                id={id}
                value={value}
                onChange={onChange}>
                {
                    data?.map((item) => <MenuItem key={item.key} value={item.value}>{item.name}</MenuItem>)
                }
            </Select>
        </FormControl>

    )
}