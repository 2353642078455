import { TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';

type HATTextFieldProps = TextFieldProps & {
    maxlength? : number
}

export const FormTextField: React.FC<HATTextFieldProps> = ({maxlength, ...props}) => {
const [length] = useState(maxlength);

    return (
        <TextField {...props}
        inputProps = {length !=null ? {maxLength : length} : {}}
        helperText = {length !=null ? ((props.value as string).length+"/"+(length)) : ""}
        />
    )

}