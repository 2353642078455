import * as Constants from "../../actions/userInfo/constants"
import { UserInfoActions } from "../../actions/userInfo/types"
import { UserInfo } from "../../../types"

export const userInfoReducer = (
    state: UserInfo = {
        fullName: "Unknown User",
        email: "no email",
        accessToken: "",
        expiresOn: new Date(),
        role: "",
        hasAccess: true,       //TODO: check what value is better as default
    },
    action: UserInfoActions
) => {
    switch (action.type) {
        case Constants.SET_USER_INFO: {
            return action.payload.userInfo
        }
        case Constants.SET_USER_VALIDATION_RESULT: {
            return { ...state, hasAccess: action.payload.hasAccess }
        }
        default: {
            return state
        }
    }
}