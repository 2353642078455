import { TextField, List, ListItemButton, ListItemText, Stack, Box } from "@mui/material"
import { FilteredListProps } from "./types"
import { useState } from "react";
import { useAppTranslationByLocation } from "../../../translations";
import { FILTERED_LIST_LABELS } from "./translations";

export const FilteredList: React.FC<FilteredListProps> = ({ listItems, containerItems, onItemSelected }) => {
    const [searchString, setSearchString] = useState('');

    const { t } = useAppTranslationByLocation()

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };

    return (
        <Stack style={{ flexGrow: 1, position: 'relative', width: '100%', height: '100%' }}>
            <TextField
                label={t(FILTERED_LIST_LABELS.SEARCH)}
                variant="outlined"
                fullWidth
                value={searchString}
                onChange={handleSearch}
            />
            {/* This is hack to make the list fill available space and then overflow
             https://stackoverflow.com/questions/14262938/child-with-max-height-100-overflows-parent
             */}
            <Box style={{ position: 'absolute', overflow: 'auto', inset: "50px 0px 0px" }}>
                <List dense key="root_list">
                    {listItems &&
                        //display simple list
                        listItems
                            .filter((data) => data.value.toLowerCase().includes(searchString.toLowerCase()))
                            .map((item) =>
                                <>
                                    <ListItemButton key={item.id} onClick={() => onItemSelected(item)}>
                                        <ListItemText key={item.value} primary={item.value} />
                                    </ListItemButton>
                                </>
                            )
                    }
                    {containerItems &&
                        //display nested lists
                        containerItems
                            .filter((data) => data.name.toLowerCase().includes(searchString.toLowerCase()))
                            .map((section) => (
                                <>
                                    <ListItemButton key={section.id} >
                                        <ListItemText key={section.id.toString()} primary={section.name} primaryTypographyProps={{ fontWeight: "bold" }} />
                                    </ListItemButton>
                                    <List dense disablePadding key={"list-" + section.id} sx={{ paddingLeft: '20px' }} >
                                        {section.items.map((item) =>
                                            <>
                                                <ListItemButton key={item.id} onClick={() => onItemSelected(item)}>
                                                    <ListItemText key={item.value} primary={item.value} />
                                                </ListItemButton>
                                            </>
                                        )}
                                    </List>
                                </>
                            ))
                    }
                </List>
            </Box>
        </Stack>
    )
}