import { HourDto, HourViewDto, ProjectDto, RecurrenceDto, RecurrencePattern, RecurrenceType, UserDto, WBSDto, UserSettingsDto } from "../../../apis/sms.web.api/generatedCode"
import { ProjectData, RecurrenceInfo, RecurrencePatternFE, RegistrationData, UserData, WBSData, UserSettings } from "../../state/types"
import { formatDate } from "../../utils"

export const mapRegistration = (r: HourViewDto): RegistrationData => {
    return {
        id: r.id!,
        projectId: r.projectId!,
        projectName: r.projectName!,
        date: new Date(r.chargeDate!),
        ticket: r.ticketNumber!,
        activity: r.activity!,
        wbsId: r.wbsId!,
        wbsName: r.wbsName!,
        wbsCode: r.wbsCode!,
        hours: r.duration!,
        typeId: r.kindId!,
        typeName: r.kindName!,
        userId: r.userId!,
        isApproved: r.isApproved!
    }
}

export const mapToChargeRecordDto = (r: RegistrationData): HourDto => {
    return {
        id: r.id > 0 ? r.id : 0,   //items with negative id's are new 
        chargeDate: formatDate(r.date),
        userId: r.userId > 0 ? r.userId : 0,
        ticketNumber: r.ticket,
        kindId: r.typeId,
        activity: r.activity,
        wbsId: r.wbsId,
        duration: r.hours
    }
}

export const mapProject = (p: ProjectDto): ProjectData => {
    return {
        id: p.id!,
        name: p.name!,
        active: p.isActive!,
        WBS: p.wbs!.map(wbs => ({
            id: wbs.id!,
            name: wbs.name!,
            code: wbs.code!,
            active: wbs.isActive!,
            projectId: p.id!
        })).sort((a, b) => a.name.localeCompare(b.name))
    }
}

export const mapUser = (user: UserDto): UserData => {
    return {
        id: user.id!,
        userName: user.userName!,
        displayName: user.displayName!,
        email: user.email!,
        isActive: user.isActive!,
    }
}

export const mapToSettings = (settings: UserSettingsDto): UserSettings => {
    return {
        darkMode: settings.darkMode!
    }
}

export const mapToRecurrenceDto = (registration: RegistrationData, recurrence: RecurrenceInfo): RecurrenceDto => {
    return {
        ...mapToChargeRecordDto(registration),
        type: RecurrenceType.Date,
        pattern: mapRecurrencePattern(recurrence.pattern),
        endDate: formatDate(recurrence.endDate),
        count: 0,
    }
}

const mapRecurrencePattern = (pattern: RecurrencePatternFE): RecurrencePattern => {
    switch (pattern) {
        case RecurrencePatternFE.DAILY: return RecurrencePattern.Daily
        case RecurrencePatternFE.WEEKLY: return RecurrencePattern.Weekly
        default: return RecurrencePattern.Daily
    }
}


export const mapWBS = (data: WBSDto): WBSData => {
    return {
        id: data.id!,
        name: data.name!,
        code: data.code!,
        active: data.isActive!,
        projectId: data.projectId!
    }
}