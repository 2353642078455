import * as React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  Home as HomeIcon,
  CalendarToday as CalendarTodayIcon,
  CalendarMonth as CalendarMonthIcon,
  AdminPanelSettings as AdminIcon
} from "@mui/icons-material";
import { SideBarMenuItemType, SideBarMenuItemProps } from "./types"
import { useAppTranslationByLocation } from "../../../../translations";
import { SIDE_BAR_MENU_ITEM_LABELS } from './translations'

const getIcon = (itemType: SideBarMenuItemType): JSX.Element => {
  switch (itemType) {
    case SideBarMenuItemType.Home: return <HomeIcon />
    case SideBarMenuItemType.Day: return <CalendarTodayIcon />
    case SideBarMenuItemType.Period: return <CalendarMonthIcon />
    case SideBarMenuItemType.Admin: return <AdminIcon />
  }
}


export const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({ open, itemType }) => {
  const { t } = useAppTranslationByLocation()

  const getText = (itemType: SideBarMenuItemType): string => {
    switch (itemType) {
      case SideBarMenuItemType.Home: return t(SIDE_BAR_MENU_ITEM_LABELS.HOME)
      case SideBarMenuItemType.Day: return t(SIDE_BAR_MENU_ITEM_LABELS.DAY)
      case SideBarMenuItemType.Period: return t(SIDE_BAR_MENU_ITEM_LABELS.PERIOD)
      case SideBarMenuItemType.Admin: return t(SIDE_BAR_MENU_ITEM_LABELS.ADMIN)
    }
  }

  return (
    <ListItem key={getText(itemType)} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {getIcon(itemType)}
        </ListItemIcon>
        <ListItemText primary={getText(itemType)} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};