export const reportDefinition = [{
    key: 1,
    value: 1,
    name: "Details report",
    fields:
    {
        projectSelect: {
            visible: true,
            required: true
        },
        userSelect: {
            visible: false,
            required: false
        },
        kindSelect: {
            visible: true,
            required: true
        },
        wbsSelect: {
            visible: true,
            required: true
        }
    }
},
{
    key: 2,
    value: 2,
    name: "Time report",
    fields:
    {
        projectSelect: {
            visible: true,
            required: true
        },
        userSelect: {
            visible: false,
            required: false
        },
        kindSelect: {
            visible: true,
            required: true
        },
        wbsSelect: {
            visible: true,
            required: true
        }
    }
}]