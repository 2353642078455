import { LoggerActions } from "../../actions/logger/types"
import * as Constants from "../../actions/logger/constants"

type LoggerState = {
    messages: Array<any>
}

export const loggerReducer = (
    state: LoggerState = { messages: [] },
    action: LoggerActions
) => {
    switch (action.type) {
        case Constants.APPEND_LOG_ERROR_MESSAGE:
        case Constants.APPEND_LOG_INFO_MESSAGE: {
            return { ...state, messages: [...state.messages, action.payload] }
        }
        default: {
            return state
        }
    }
}