export const DAY_INPUT_FORM_LABELS = {
    SELECT_KIND: 'app.dayInputForm.selectKind',
    SELECT_PROJECT: 'app.dayInputForm.selectProject',
    SELECT_WBS: 'app.dayInputForm.selectWBS',
    TICKET_NUMBER: 'app.dayInputForm.ticketNumber',
    ACTIVITY: 'app.dayInputForm.activity',
    HOURS: 'app.dayInputForm.hours',
    CREATE: 'app.common.labels.create',
    UPDATE: 'app.common.labels.update',
    RECURRENCE_OPTIONS: 'app.dayInputForm.recurrenceOptions',
    RECURRENCE_TYPE: 'app.dayInputForm.recurrenceType',
    RECURRENCE_END_DATE: 'app.dayInputForm.recurrenceEndDate',
    DAILY: 'app.dayInputForm.daily',
    WEEKLY: 'app.dayInputForm.weekly',
    DUPLICATE: 'app.dayInputForm.duplicate',
}