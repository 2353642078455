import { BulkChangeModalProps } from './types'
import { modalStyle } from "../../../../components/styling";
import { Modal, Box, Stack, Button, SelectChangeEvent } from '@mui/material';
import { FormSelect, FormTextField } from '../../../../components/formElements';
import { useAppTranslationByLocation } from "../../../../translations";
import { useCallback, useEffect, useState } from 'react';
import { BULK_CHANGE_MODAL_LABELS } from './translations';
import { RegistrationsListing } from '../registrationsListing';
import { RegistrationData } from '../../../../state/types';

export const BulkChangeModal: React.FC<BulkChangeModalProps> = ({ open, onClose, registrations, projects, kinds, onFormSubmitted }) => {
    const [selectedProjectId, setSelectedProjectId] = useState<string>('')
    const [selectedWbsId, setSelectedWbsId] = useState<string>('')
    const [selectedKindId, setSelectedKindId] = useState<string>('')
    const [ticketNumber, setTicketNumber] = useState<string>('');
    const [selectedRegistrations, setSelectedRegistrations] = useState<RegistrationData[]>([])

    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        if (registrations.length > 0) {
            setSelectedProjectId(registrations[0].projectId.toString())
            setSelectedWbsId(registrations[0].wbsId.toString())
            setSelectedKindId(registrations[0].typeId.toString())
            setTicketNumber(registrations[0].ticket)
        }
    }, [registrations])

    const handleKindChange = (event: SelectChangeEvent) => {
        setSelectedKindId(event.target.value);
    }

    const handleProjectChange = (event: SelectChangeEvent) => {
        setSelectedProjectId(event.target.value);
        const selectedProject = projects.find(proj => Number(event.target.value) === proj.id)
        if (selectedProject?.WBS && selectedProject.WBS.length === 1) {
            setSelectedWbsId(selectedProject.WBS[0].id.toString())
        } else {
            setSelectedWbsId('');
        }
    }

    const handleWbsChange = (event: SelectChangeEvent) => {        
        setSelectedWbsId(event.target.value);
    }

    const handleTicketNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTicketNumber(event.target.value);
    }

    const handleSelectedRegistrationsChange = useCallback((registrations: RegistrationData[]) => {
        setSelectedRegistrations(registrations)
    }, [])

    //update selected registrations with data from the form
    const updatedRegistrations = (): RegistrationData[] => {
        return selectedRegistrations.map(r => ({
            ...r,
            projectId: parseInt(selectedProjectId),
            wbsId: parseInt(selectedWbsId),
            typeId: parseInt(selectedKindId),
            ticket: ticketNumber
        }))
    }

    const handleSubmit = () => {
        onFormSubmitted(updatedRegistrations())
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={[modalStyle, { width: 600 }]}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ mt: 1 }}>
                        <FormSelect
                            id="select-project"
                            label={t(BULK_CHANGE_MODAL_LABELS.SELECT_PROJECT)}
                            value={selectedProjectId}
                            data={projects.map(item => ({ key: item.id, value: item.id, name: item.name }))}
                            onChange={handleProjectChange} />

                        <FormSelect
                            id="select-wbs"
                            label={t(BULK_CHANGE_MODAL_LABELS.SELECT_WBS)}
                            value={selectedWbsId}
                            data={projects.find((c) => c.id === parseInt(selectedProjectId))?.WBS.map(
                                item => ({ key: item.id, value: item.id, name: `${item.code} - ${item.name}` })
                            )}
                            onChange={handleWbsChange} />

                        <FormSelect id="select-kind"
                            label={t(BULK_CHANGE_MODAL_LABELS.SELECT_KIND)}
                            value={selectedKindId}
                            data={kinds.map(item => ({ key: item.id, value: item.id, name: item.name }))}
                            onChange={handleKindChange} />

                        <FormTextField fullWidth
                            id='input-ticket-number'
                            label={t(BULK_CHANGE_MODAL_LABELS.TICKET_NUMBER)}
                            value={ticketNumber}
                            onChange={handleTicketNumberChange} />

                        <RegistrationsListing
                            registrations={registrations.sort((a, b) => (a.date.getDate() - b.date.getDate()))}
                            onChange={handleSelectedRegistrationsChange} />

                        <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={onClose}>
                                {t(BULK_CHANGE_MODAL_LABELS.CLOSE)}
                            </Button>
                            <Button type="submit"
                                variant="contained"
                                disabled={selectedRegistrations.length === 0}>
                                {t(BULK_CHANGE_MODAL_LABELS.UPDATE)}
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </Modal>
    )
}